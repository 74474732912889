
const mergeIntoQuery = (params: any): URLSearchParams => {
  // Get existing query string from the browser
  const queryStringParams = new URLSearchParams(window.location.search)
  // Convert it to a normal JS object
  const existingParamsObject = Object.fromEntries(queryStringParams.entries())

  // Same thing, but for the passed in searchParams.
  const newParamsObject = Object.fromEntries((new URLSearchParams(params)).entries());

  // Merge the two while prioritizing new params
  const mergedObject = { ...existingParamsObject, ...newParamsObject }
  const newQuery = new URLSearchParams(mergedObject)

  history.replaceState(null, document.title, `?${newQuery.toString()}`)

  return newQuery
}

const removeFromQuery = (keyToRemove: string): URLSearchParams => {
  const queryStringParams = new URLSearchParams(window.location.search)
  queryStringParams.delete(keyToRemove)

  history.replaceState(null, document.title, `?${queryStringParams.toString()}`)

  return queryStringParams
}

export {
  mergeIntoQuery,
  removeFromQuery
}