import React from 'react'
import ReactDOM from 'react-dom'

const mountComponents = () => {
  // Get all tags generated by the `rails_component` helper.
  const mountingElements = document.querySelectorAll('[data-react-component]')

  for (const element of mountingElements) {
    // Extract name and props from the data present in the element
    const { name, props } = JSON.parse(element.getAttribute('data-react-component'))

    // Retrieve the component from the `components` object we mounted before,
    // and check if it exists.
    const component = components[name]
    if (!component) {
      console.log(`React: Component ${name} not found`)
      continue
    } else {
      console.log(`React: Mounting ${name}`)
    }

    // Mount the component with the props inside the element
    ReactDOM.render(
      React.createElement(component, props || {}),
      element
    )
  }
}

// This will be the object populated
const components = {}

// Here, we load the files from the `app/javascript/components` folder
const context1 = require.context(`./components`, true, /\.tsx$/)
for (const key of context1.keys()) {
  const cleanName = key.replace('./', "").replace(".tsx", "")
  components[cleanName] = context1(key).default
}

// Here, we load the files from the `app/javascript/components` folder
const context2 = require.context(`./admin_components`, true, /\.tsx$/)
for (const key of context2.keys()) {
  const cleanName = key.replace('./', "").replace(".tsx", "")
  components[cleanName] = context2(key).default
}


console.log(components)

document.addEventListener('DOMContentLoaded', mountComponents)
document.addEventListener('turbo:load', mountComponents)
document.addEventListener('turbo:frame-load', mountComponents)
document.addEventListener('turbo:before-render', (e) => { e && e.target && ReactDOM.unmountComponentAtNode(e.target) })
