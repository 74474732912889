import { SetupIntent } from '@stripe/stripe-js'
import React, { useState, useRef, useMemo } from 'react'
import CreditCardInput from "./CreditCardInput"
import SubmitButton from './SubmitButton'

type Props = {
  stripe_key: "tech" | "foundation"
}

const CreditCardSetupControl = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [cardInfo, setCardInfo] = useState<SetupIntent>()

  return (
    <div className="give-form">
      <div className="give-form-group">
        <span className="give-label">
          Credit card
          </span>

        <div className="give-stripe-input give-form-group__input">
          <CreditCardInput
            stripeKey={props.stripe_key}
            onLoading={(loading) => setLoading(loading)}
            onError={(error) => setError(error.message)}
            onSuccess={(card) => setCardInfo(card)}
          />
        </div>

        {
          error
          &&
          <div className="give-error">{error}</div>
        }
      </div>

      <input type="hidden" name="payment_method[stripe_id]" value={cardInfo?.payment_method}/>

      <SubmitButton text="Continue" loading={loading} disabled={!cardInfo} />
    </div>
  )
}

export default CreditCardSetupControl