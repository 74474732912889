import React, { useState, useRef, useEffect } from 'react'
import ImageCropper from './ImageCropper'

type Props = {
  existingPicture?: string
}

const ProfilePicture = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const previewRef = useRef<HTMLDivElement>(null)

  const [modalOpen, setModalOpen] = useState(false)
  const [src, setSrc] = useState('' as string | ArrayBuffer)
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [croppedSrc, setCroppedSrc] = useState(null as null | string)

  const onSelectClick = e => {
    inputRef.current.click()
  }

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        setSrc(reader.result)
        setModalOpen(true)
      })

      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onCropFinished = (croppedImage: string) => {
    setCroppedSrc(croppedImage)

    previewRef.current.style.backgroundImage = `url('${croppedImage}')`
    previewRef.current.classList.add('company-logo__logo-selected')
  }

  const cancel = () => {
    if (!hasConfirmed && !props.existingPicture) {
      previewRef.current.style.backgroundImage = ''
      previewRef.current.classList.remove('company-logo__logo-selected')
    }

    setSrc('')
    setModalOpen(false)
  }

  return (
    <div className="company-logo__logo-picture-wrapper give-form-group__input">
      <div
        className={`company-logo__logo-picture ${props.existingPicture && 'company-logo__logo-selected'}`}
        id='picture-preview'
        ref={previewRef}
        onClick={onSelectClick}
        style={props.existingPicture ? { backgroundImage: `url('${props.existingPicture}')` } : {}}
      ></div>

      <a
        className="give-button"
        id='select-photo'
        onClick={onSelectClick}
      >
        {hasConfirmed || props.existingPicture ? 'Change logo' : 'Upload logo'}
      </a>

      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        name="picture-input"
        id="picture-input"
        className="hidden"
        onChange={onSelectFile}
      />

      <input type="hidden" name="company[logo_data_uri]" value={croppedSrc || ""} />

      {
        modalOpen
        &&
        <ImageCropper
          imageSrc={src}
          aspect={1}
          onCropFinished={onCropFinished}
          closeFn={() => {
            setHasConfirmed(true)
            setModalOpen(false)
          }}
          cancelFn={cancel}
        />
      }
    </div>
  )
}

export default ProfilePicture