import React, { useEffect, useMemo } from 'react'
import { useTable, useSortBy, useColumnOrder, useBlockLayout, useResizeColumns, usePagination } from 'react-table'
import State, { SearchResult } from './state'
import AthenaClient from './AthenaClient'
import { mergeIntoQuery } from './mergeIntoQuery'
import useLocalStorage from '../../useLocalStorage'
import { useDebouncedCallback} from 'use-debounce'

type ResultsTableProps = {
  results: SearchResult[]
  collections: string[]
}

const currencyFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' })

const CheckAllHeader = () => {
  const selectedRowIds = State.useState(s => s.selectedRowIds)
  const results = State.useState(s => s.results)
  const isChecked = (selectedRowIds.length === results.length) && (selectedRowIds.length > 0)

  const handleCheck = () => {
    State.update(s => {
      if (s.selectedRowIds.length === s.results.length) {
        s.selectedRowIds = []
      } else {
        let ids = s.results.map(r => r.id)
        console.log(ids)
        s.selectedRowIds = ids
      }
    })
  }

  return (
    <div className="custom-control custom-checkbox" key="check-all-checkbox">
      <input type="checkbox" className="custom-control-input" id="select-all" onChange={handleCheck} checked={isChecked} />
      <label className="custom-control-label" htmlFor="select-all" id="select-all-label"></label>
    </div>
  )
}

const RowCheckbox = (props: {id: string}) => {
  const rowIds = State.useState(s => s.selectedRowIds)
  const isChecked = rowIds.includes(props.id)

  const handleCheck = () => {
    State.update(s => {
      if (s.selectedRowIds.includes(props.id)) {
        const index = s.selectedRowIds.indexOf(props.id)
        s.selectedRowIds.splice(index, 1)
      } else {
        s.selectedRowIds.push(props.id)
      }
    })
  }

  return (
    <div className="custom-control custom-checkbox" key={`checkbox-${props.id}`}>
      <input type="checkbox" className="custom-control-input" id={`row-select-${props.id}`} onChange={handleCheck} checked={isChecked} />
      <label className="custom-control-label" htmlFor={`row-select-${props.id}`}></label>
    </div>
  )
}

const showCharity = async (registration_number: string) => {
  const charity = await AthenaClient.getListing(registration_number)

  State.update(s => {
    s.current_charity_id = registration_number
    s.current_charity = charity

    mergeIntoQuery({ current_charity_id: registration_number })
  })
}

const columnDefault = [
  {
    id: "SelectAllRows",
    Header: <CheckAllHeader />,
    width: 40,
    disableSortBy: true,
    disableReorder: true,
    disableResize: true,
    Cell: ({ row }: { row: { original: SearchResult } }) => <RowCheckbox id={row.original.id} />
  },
  {
    id: "registration_number",
    Header: "Registration",
    accessor: "registration_number",
    width: 140
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
    Cell: ({ row }) => <a onClick={() => showCharity(row.original.registration_number)}>{row.original.name}</a>,
    width: 350,
  },
  {
    id: "location",
    Header: "Location",
    accessor: (row) => `${row.city}, ${row.state_province}, ${row.postal_code}`,
    width: 140
  },
  {
    id: "website",
    Header: "Website",
    accessor: "url",
    Cell: ({ value }) => value ? <a href={value} target="_blank">{value}</a> : "",
    width: 140
  },
  {
    id: "expenditures",
    Header: "Expenditures",
    accessor: "expenditures",
    Cell: ({ value }) => value ? currencyFormatter.format(value) : '',
    className: "align-right",
    width: 140,
    sortType: 'number'
  },
  {
    id: "liquid_assets",
    Header: "Liquid assets",
    accessor: "liquid_assets",
    Cell: ({ value }) => value ? currencyFormatter.format(value) : '',
    className: "align-right",
    width: 140,
    sortType: 'number'
  },
  {
    id: "fundraising_ratio",
    Header: "Fundraising ratio",
    accessor: "fundraising_ratio",
    Cell: ({ value }) => value ? `${(value * 100.0).toFixed(2)}%` : '',
    className: "align-right",
    width: 140,
    sortType: 'number'
  },
  {
    id: "need_for_funding",
    Header: "Years of funding",
    accessor: "need_for_funding",
    Cell: ({ value }) => value ? value.toFixed(2) : '',
    className: "align-right",
    width: 140,
    sortType: 'number'
  },
  {
    id: "position_300plus",
    Header: "$300k+ positions",
    accessor: "position_300plus",
    className: "align-right",
    width: 140,
    sortType: 'number'
  }
]

const ResultsTable = (props: ResultsTableProps) => {
  const selectedRowCount = State.useState(s => s.selectedRowIds.length)

  const [savedColumnOrder, setSavedColumnOrder] = useLocalStorage("athenaColumnOrderState", columnDefault.map((c) => c.id))
  const [savedColumnWidths, setSavedColumnWidths] = useLocalStorage("athenaColumnWidth", columnDefault.reduce((obj, c) => { obj[c.id] = c.width; return obj }, {}))

  const columns = useMemo(() => {
    let orderedColumns = savedColumnOrder.map((id) => columnDefault.find(c => c.id === id))
    return orderedColumns.map((c) => {
      c.width = savedColumnWidths[c.id]
      return c
    })
  }, [savedColumnOrder, savedColumnWidths])

  const tableInstance = useTable(
    {
      columns,
      data: props.results,
      initialState: { pageSize: 250 }
    },
    useSortBy,
    useColumnOrder,
    useResizeColumns,
    useBlockLayout,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    setColumnOrder,
    // pagination options
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state
  } = tableInstance

  const pageIndex = state.pageIndex

  useEffect(() => {
    if (state.columnOrder.length > 0) {
      setSavedColumnOrder(state.columnOrder)
    }
  },
    [
      state.columnOrder,
      state.sortBy
    ]
  )

  const debouncedSizeSave = useDebouncedCallback(() => {
    let keys = Object.keys(state.columnResizing.columnWidths)
    if (keys.length === 0) { return }

    console.log(`Column resize: ${JSON.stringify(state.columnResizing.columnWidths)}`)
    setSavedColumnWidths({ ...savedColumnWidths, ...state.columnResizing.columnWidths })
  }, 100)

  useEffect(debouncedSizeSave, [state.columnResizing])

  const moveColumn = (column, indexDelta) => {
    if (column.id === "SelectAllRows") return

    const ids = visibleColumns.map((c) => c.id)
    const currentIndex = ids.indexOf(column.id)

    // Don't let the second column replace the first.
    if (currentIndex === 1 && indexDelta === -1) {
      return
    }

    // Don't let the last column replace the first by wrapping around.
    if (currentIndex === ids.length-1 && indexDelta === 1) {
      return
    }

    ids.splice(currentIndex + indexDelta, 0, ids.splice(currentIndex, 1)[0])

    setColumnOrder(ids)
  }

  const addToCollection = async (collection: string) => {
    const selectedRowIds = State.getRawState().selectedRowIds

    if (selectedRowIds.length === 0) return

    await AthenaClient.bulkUpdateListings(selectedRowIds, { collection })

    State.update(s => {
      s.selectedRowIds = []
    })
  }

  const hideSelected = async (e: any) => {
    e.preventDefault()

    const selectedRowIds = State.getRawState().selectedRowIds

    if (selectedRowIds.length === 0) return

    State.update(s => {
      s.selectedRowIds = []

      selectedRowIds.forEach((id) => {
        var index = s.results.findIndex(row => row.id === id)
        s.results.splice(index, 1)
      })
    })

    await AthenaClient.bulkUpdateListings(selectedRowIds, { hide_selected: true })
  }

  return (
    <div className="admin-grid__column admin-grid__column--scroll">
      <div className="table-actions align-items-center">
        <div className="btn-group">
          <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Add to collection
          </button>

          <div className="dropdown-menu" id="collection-menu">
            {
              props.collections.map((collection) => {
                return <a className="dropdown-item" onClick={(e) => { e.preventDefault(); addToCollection(collection)} } key={collection}>{ collection }</a>
              })
            }
          </div>
        </div>

        <a href="#" className="btn btn-primary ml-2" onClick={hideSelected}>Hide selected</a>

        <div className="text-muted ml-2">{selectedRowCount} rows selected</div>
      </div>

      <div {...getTableProps()} className="table-card admin-grid__column admin-grid__column--scroll">
        <div className="table-head">
          {
            headerGroups.map(headerGroup => (
              <div {...headerGroup.getHeaderGroupProps()} className="table-row">
                {
                  headerGroup.headers.map(column => (
                    <div {...column.getHeaderProps([{
                      className: column.className
                    }])} className="table-cell">
                      <div {...column.getHeaderProps([{
                        ...column.getSortByToggleProps(),
                      }])}>
                        {
                          column.isSorted
                            ?
                            <strong>{column.render('Header')}</strong>
                            :
                            column.render('Header')
                        }
                        <span>
                          {
                            column.disableSortBy
                              ?
                              ""
                              :
                              (column.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ' ↕')
                          }
                        </span>
                      </div>
                      {
                        column.disableReorder
                        ?
                          <></>
                        :
                          <div className="column-reordering">
                            <div className="button" onClick={() => { moveColumn(column, -1) }}>{'<'}</div>
                            <div className="button" onClick={() => { moveColumn(column, 1) }}>{'>'}</div>
                          </div>
                      }
                      {
                        column.disableResize
                        ?
                          <></>
                        :
                          <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? 'isResizing' : ''}`} />
                      }
                    </div>
                  ))}
              </div>
            ))
          }
        </div>

        <div {...getTableBodyProps()}>
          {
            page.map(row => {
              prepareRow(row)

              return (
                <div {...row.getRowProps([{ className: `table-row ${row.original.exceeds_limits.length > 0 ? 'over_highlight' : ''}` }])}>
                  {
                    row.cells.map(cell => (
                      <div {...cell.getCellProps([{
                        className: `table-cell ${cell.column.className} ${cell.row.original.exceeds_limits.includes(cell.column.id) ? 'over_highlight' : ''}`
                      }])}>
                        {// Render the cell contents
                          cell.render('Cell')}
                      </div>
                    ))
                  }
                </div>
              )
            })
          }
        </div>
      </div>

      <div className="table-actions align-items-center mt-2">
        <button className="btn btn-primary mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button className="btn btn-primary mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button className="btn btn-primary mr-2" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button className="btn btn-primary mr-2" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>

        <span className="text-muted">
          Page {pageIndex + 1} of {pageOptions.length}
        </span>
      </div>
    </div>
  )
}

export default ResultsTable