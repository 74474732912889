import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import SortableTable from './SortableTable'

const numberToCurrency = (value) => {
  let rounded = Math.round(value * 100.0) / 100.0
  return `$${rounded.toFixed(2)}`
}

declare global {
  interface Window {
    openPaymentModal: any;
    giveData: any;
  }
}

const DisbursementTable = (props) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Charity Name',
        accessor: 'charity_name',
        Cell: (state) => <a href={state.row.original.charity_url}>{state.cell.value}</a>
      },
      {
        Header: 'Amount in Period',
        accessor: 'amount_in_period',
        Cell: (s) => numberToCurrency(s.cell.value)
      },
      {
        Header: 'Total to Payout',
        accessor: 'total_to_payout',
        Cell: (state) => {
          let data = state.row.original
          let colourClass

          if (data.total_to_payout >= 100) {
            colourClass = data.payment ? 'yellow' : 'red'
          } else {
            colourClass = 'black'
          }

          return (
            <span className={colourClass}>{numberToCurrency(data.total_to_payout)}</span>
          )
        }
      },
      {
        Header: 'Payment',
        Cell: (state) => {
          let data = state.row.original

          if (!data.payment && data.total_to_payout >= 100) {
            return (
              <a href='#' onClick={(e) => { e.preventDefault(); window.openPaymentModal(data) }} className='btn btn-primary btn-sm'>Add Payment</a>
            )
          } else if (data.payment) {
            if (data.payment.method === 'transfer') {
              // Transfers are auto marked as received.
              return ''
            } else if (data.payment.method === 'cheque') {
              if (!data.payment.received) {
                return (
                  <a href={data.mark_received_url} className='btn btn-primary btn-sm' data-confirm="Are you sure?">Mark ${data.payment.amount / 100.0} Received</a>
                )
              } else {
                // Cheque marked as received
                return ''
              }
            } else {
              return ''
            }
          } else {
            // Hasn't $100 yet and doesn't have a payment logged against it.
            return ''
          }
        }
      }
    ],
    []
  )

  const data = window.giveData.disbursementTableData

  return (
    <SortableTable columns={columns} data={props.data} />
  )
}

export default DisbursementTable