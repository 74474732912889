import React, { useEffect, useState } from 'react'

type Props = {
  text: string
  savedText?: string
}

const SubmitButton = (props: Props) => {
  const text = props.text ?? "Continue"
  const [loading, setLoading] = useState(false)
  const [savedText, setSavedText] = useState<string | null>(props.savedText)

  useEffect(() => {
    const timeout = setTimeout(() => setSavedText(null), 2000)

    return () => clearTimeout(timeout)
  }, [props.savedText])

  const onSubmit = (event) => {
    // Chrome seems to require this timeout here.
    // If we set loading synchronously, Chrome won't
    // submit the containing <form>, wtf?
    setTimeout(() => {
      setLoading(true)
    })
  }

  return (
    <>
      <button
        type="submit"
        disabled={loading}
        className={`give-button ${loading && 'give-button--loading'}`}
        onClick={onSubmit}
      >
        {loading ? '' : text}
      </button>

      {
        savedText
        &&
        <div className="save-notice">{props.savedText}</div>
      }
    </>
  )
}

export default SubmitButton