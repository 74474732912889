import React, { useState, useMemo } from "react"
import NumberFormat from 'react-number-format';

type Props = {
  companyName: string
  max_match_percentage: number
  same_percent_every_cause: boolean
  per_donor_limit: number
}

function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max));
}

const MatchEstimator = (props: Props) => {
  const [matchPercent, setMatchPercent] = useState(props.max_match_percentage)
  const [matchLimit, setMatchLimit] = useState(props.per_donor_limit/100.0)

  const [employeeCount, setEmployeeCount] = useState(30)
  const [givingPercent, setGivingPercent] = useState(0.5)
  const [donationAmount, setDonationAmount] = useState(60)

  const amountEstimate = useMemo(
    () => {
      let donation = clamp(donationAmount * (matchPercent / 100.0), 0, matchLimit)
      return Math.round(employeeCount * givingPercent * donation)
    },
    [givingPercent, donationAmount, employeeCount, matchPercent, matchLimit]
  )

  const maxEstimate = useMemo(
    () => {
      let donation = clamp(donationAmount * (matchPercent / 100.0), 0, matchLimit)
      return Math.round(employeeCount * donation)
    },
    [donationAmount, employeeCount, matchPercent, matchLimit]
  )

  const parseNumberInput = (value: string) => {
    if (value == '') {
      return 0
    } else {
      return parseInt(value.replace(/\D/g, ''), 10)
    }
  }

  const parsePercentInput = (value: string) => {
    if (value == '') {
      return 0
    } else {
      let num = parseInt(value.replace("%", ""), 10)
      if (isNaN(num)) num = 0
      return Math.trunc( num / 100.0)
    }
  }

  const formatPercent = (value: number) => {
    return `${Math.round(value * 100.0)}%`
  }

  const parseCurrencyInput = (value: string) => {
    if (value == '') {
      return 0
    } else {
      return Math.trunc(parseInt(value.replace("$", ""), 10))
    }
  }

  const formatCurrency = (value: number) => {
    return `$${Math.round(value).toLocaleString()}`
  }

  return (
    <div className="matchForm">
      <div className="give-form-group">
        <label htmlFor="matching_program_match_percent" className="give-label">Match percentage</label>
        <div className="give-form-group__input">
          <NumberFormat className="give-input" defaultValue={props.max_match_percentage} onValueChange={(values) => setMatchPercent(parseInt(values.value, 10) || 0)} suffix="%" name="matching_program[match_percent]" id="matching_program_match_percent" />
          <div className="give-form-group__hint">{props.companyName} will match up to this percentage of an employee's donation.</div>
        </div>
        <div className="give-checkbox-group give-form-group">
          <input name="matching_program[same_percent_every_cause]" type="hidden" value="0" />
          <input className="give-checkbox" type="checkbox" value="1" defaultChecked={props.same_percent_every_cause} name="matching_program[same_percent_every_cause]" id="matching_program_same_percent_every_cause" />

          <label className="give-checkbox-label give-label" htmlFor="matching_program_same_percent_every_cause">Match the same percentage for every cause</label>
        </div>
      </div>

      <div className="give-form-group">
        <label className="give-label" htmlFor="matching_program_per_donor_limit">Match limit</label>
        <div className="give-form-group__input">
          <NumberFormat className="give-input" defaultValue={props.per_donor_limit / 100.0} onValueChange={(values) => setMatchLimit(parseInt(values.value, 10) || 0)} thousandSeparator={true} prefix="$" name="matching_program[per_donor_limit]" id="matching_program_per_donor_limit" />
          <div className="give-form-group__hint">Per employee per month. We find that most donate about $50/month, on average.</div>
        </div>
      </div>

      <div className="match-estimate">
        <p className="match-estimate__input">
          There are employees <NumberFormat className="match-estimate__input--editable" onValueChange={(values) => setEmployeeCount(parseInt(values.value, 10) || 0)} defaultValue={0} value={employeeCount} />
          at {props.companyName}. <NumberFormat className="match-estimate__input--editable" onValueChange={(values) => setGivingPercent((parseInt(values.value, 10) || 0)/100)} defaultValue={0} value={givingPercent * 100} suffix="%" /> of them are donating, at an
          average of <NumberFormat className="match-estimate__input--editable" onValueChange={(values) => setDonationAmount(parseInt(values.value, 10) || 0)} defaultValue={0} value={donationAmount} prefix="$" /> a month.
        </p>

        <div className="match-estimate__output">
          <div className="match-estimate__output-block">
            <div className="match-estimate__output-header">
              <h3 className="match-estimate__output-label">
                Estimate
                </h3>
              <h3 className="match-estimate__output-value">
                {formatCurrency(amountEstimate)}
                </h3>
            </div>
            <p className="match-estimate__output-text">
              {Math.round(employeeCount * givingPercent)} employees, {formatCurrency(clamp(donationAmount * (matchPercent / 100.0), 0, matchLimit))} matched every month
              </p>
          </div>

          <div className="match-estimate__output-block">
            <div className="match-estimate__output-header">
              <h3 className="match-estimate__output-label">
                Maximum
                </h3>
              <h3 className="match-estimate__output-value">
                {formatCurrency(maxEstimate)}
                </h3>
            </div>
            <p className="match-estimate__output-text">
              {employeeCount} employees, {formatCurrency(clamp(donationAmount * (matchPercent / 100.0), 0, matchLimit))} matched every month
              </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MatchEstimator

//<input className="match-estimate__input--editable" onChange={(e) => setEmployeeCount(parseNumberInput(e.target.value))} value={employeeCount} />