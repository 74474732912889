import * as React from "react";
import { TrixEditor } from "react-trix";

type Props = {
  note: string
  onChange: (note: string) => void
}

const NotesEditor = (props: Props) => {

  const handleEditorReady = (editor) => {
  }

  const handleChange = (html, text) => {
    props.onChange(html)
  }

  return (
    <TrixEditor value={props.note + ""} mergeTags={[]} onChange={handleChange} onEditorReady={handleEditorReady} />
  )
}

export default NotesEditor