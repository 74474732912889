import { Store } from "pullstate"

export type LimitTypes = "website" | "need_for_funding" | "fundraising_ratio" | "expenditures" | "position_300plus"

export type SearchResult = {
  id: string
  registration_number: string
  name: string
  description: string
  alt_description: string
  address: string
  city: string
  state_province: string
  postal_code: string
  country: string
  url: string
  is_visible: boolean
  expenditures: number
  liquid_assets: number
  fundraising_ratio: number
  need_for_funding: number
  position_300plus: number | null
  has_annual_report: boolean
  has_financial_statements: boolean
  exceeds_limits: LimitTypes[]
  generated_tags: string[]
  custom_tags: string[]
  collection_tags: string[]
  geographic_scope_tags: string[]
}

export function toBoolean(value, defaultValue = false) {
  if (typeof (value) === 'string') {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    case false:
    case "false":
    case 0:
    case "0":
    case "off":
    case "no":
      return false;
    default:
      return defaultValue;
  }
}

const params = new URLSearchParams(window.location.search)

const State = new Store({
  searchOnLoad: (params.get("query") || "").length > 0 && !params.get("current_charity_id"),
  isSearching: false,
  lastSearchTime: null,
  selectedRowIds: [] as string[],
  current_charity_id: params.get("current_charity_id") || null,
  current_charity: null,

  search: {
    query: params.get("query") || '',

    city_fund_search: params.get("city_fund_search") || "all",
    collection_name: params.get("collection_name") || 'visible',
    geographic_scope: params.get("geographic_scope") || '',

    state_province: params.get("state_province") || '',

    expenditures_min: parseInt(params.get("expenditures_min"), 10) || 50000,
    expenditures_max: parseInt(params.get("expenditures_max"), 10) || 100000000,

    fundraising_ratio_min: parseInt(params.get("fundraising_ratio_min"), 10) || 0,
    fundraising_ratio_max: parseInt(params.get("fundraising_ratio_max"), 10) || 30,

    years_of_funding_min: parseInt(params.get("years_of_funding_min"), 10) || 0,
    years_of_funding_max: parseInt(params.get("years_of_funding_max"), 10) || 5,

    include_position_300plus: toBoolean(params.get("include_position_300plus"), true),
    hide_without_website: toBoolean(params.get("hide_without_website")),
    has_annual_report: toBoolean(params.get("has_annual_report")),
    has_financial_statements: toBoolean(params.get("has_financial_statements"))
  },
  results: [] as SearchResult[],
  collections: [] as string[],
  geographic_scopes: [] as string[]
});

(window as any).athenaState = State

export default State