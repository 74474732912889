import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss'

import Modal from './Modal'

type Props = {
  aspect: number
  imageSrc: string | ArrayBuffer
  onCropFinished: (image: string) => void
  closeFn: () => void
  cancelFn: () => void
}

type State = {
  src: string | ArrayBuffer,
  crop: {
    unit: '%' | 'px',
    width?: number,
    height?: number
    aspect?: number
  },
  croppedImage?: string
}

class ImageCropper extends PureComponent<Props, State> {
  imageRef: HTMLImageElement
  fileUrl: string

  constructor(props: Props) {
    super(props)

    this.state = {
      src: props.imageSrc,
      crop: {
        unit: '%',
        width: 50,
        aspect: props.aspect,
      },
    }
  }

  onImageLoaded(image: HTMLImageElement) {
    this.imageRef = image;
  }

  onCropComplete(crop) {
  }

  onCropChange(crop) {
    this.setState({ crop })
  }

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // If the image is really large, then scale it down for upload.
    // We don't need to upload the full thing just to have the server
    // make it even smaller.
    let scaleFactor = (image.naturalWidth > 3000 || image.naturalHeight > 3000) ? 2 : 1

    canvas.width = (crop.width * scaleX) / scaleFactor;
    canvas.height = (crop.height * scaleY) / scaleFactor;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    const base64 = canvas.toDataURL('image/png')
    return base64
    // this.setState({ croppedImage: base64 })
  }

  close() {
    this.props.onCropFinished(this.getCroppedImg(this.imageRef, this.state.crop))
    this.props.closeFn()
  }

  render() {
    return (
      <div className="image-cropper">
        {this.state.src && (
          <Modal title="Crop your logo">
            <ReactCrop
              src={this.state.src}
              crop={this.state.crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded.bind(this)}
              onComplete={this.onCropComplete.bind(this)}
              onChange={this.onCropChange.bind(this)}
            />

            <div className="modal__buttons">
              <div id="continue-crop" className="give-button" onClick={this.close.bind(this)}>
                Continue
              </div>

              <div className="give-text-link" onClick={this.props.cancelFn}>
                Cancel
              </div>
            </div>
          </Modal>
        )}
      </div>
    )
  }
}

export default ImageCropper