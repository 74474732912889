import { Tag } from 'react-tag-autocomplete'

import { SearchResult } from './state'
import { mergeIntoQuery } from './mergeIntoQuery'
import { CharityListing } from './CharityView'

type BulkUpdateParams = Partial<CharityListing> & {
  collection?: string,
  hide_selected?: boolean
}

class AthenaClient {
  static async search (searchParams: any): Promise<SearchResult[]> {
    const newUrlParams = mergeIntoQuery(searchParams)
    // Remove the params we don't need for the search.
    newUrlParams.delete("current_charity_id")

    const searchResponse = await fetch(`/admin/research/search?${newUrlParams.toString()}`)
    const results = await searchResponse.json() as { results: SearchResult[] }
    return results.results
  }

  static async getListing (id: string): Promise<CharityListing> {
    const listingResponse = await fetch(`/admin/research/search/${id}`)
    const results = await listingResponse.json() as { listing: CharityListing }
    return results.listing
  }

  static async updateListing (id: string, attributes: Partial<CharityListing>): Promise<CharityListing> {
    const listingResponse = await fetch(`/admin/research/athena/${id}`, {
      method: "PUT",
      body: JSON.stringify({ charity: attributes}),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content
      }
    })
    const results = await listingResponse.json() as { listing: CharityListing }
    return results.listing
  }

  static async bulkUpdateListings (ids: string[], attributes: BulkUpdateParams): Promise<CharityListing[]> {
    const listingResponse = await fetch(`/admin/research/athena/bulk_update`, {
      method: "PUT",
      body: JSON.stringify({ ids, attributes }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content
      }
    })

    const results = await listingResponse.json() as { listings: CharityListing[] }

    return results.listings
  }

  static async tagSuggestions (id: string, context: string, query: string): Promise<Tag[]> {
    const response = await fetch(`/admin/research/athena/${id}/tag_suggestions?query=${query}&context=${context}`)
    let tags = await response.json() as Tag[]
    return tags
  }

  static async addTag (id: string, context: string, tag: Tag): Promise<Tag[]> {
    const response = await fetch(
      `/admin/research/athena/${id}/add_tag`,
      {
        method: "PUT",
        body: JSON.stringify({ tag_name: tag.name, context: context }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content
        }
      }
    )

    let tags = await response.json() as Tag[]
    return tags
  }

  static async removeTag(id: string, context: string, tag: Tag): Promise<Tag[]> {
    const response = await fetch(
      `/admin/research/athena/${id}/remove_tag`,
      {
        method: "PUT",
        body: JSON.stringify({ tag_name: tag.name, context: context }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content
        }
      }
    )

    let tags = await response.json() as Tag[]
    return tags
  }
}

export default AthenaClient
