import React, { useState } from "react"
type Props = {
  url: string
}

const CopyURLInput = (props: Props) => {
  const [linkText, setLinkText] = useState("Copy")

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.url).then(function () {
      setLinkText("Copied!")
      setTimeout(() => {
        setLinkText("Copy")
      }, 1500)
    })
  }

  return (
    <div className="give-input-action-wrapper">
      <input className="give-input" value={props.url} disabled readOnly />
      <span className="give-input-action" onClick={copyToClipboard}>
        { linkText }
      </span>
    </div>
  )
}

export default CopyURLInput