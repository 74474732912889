import React, { useEffect, useState } from "react"
import { removeFromQuery } from "./mergeIntoQuery"
import State from "./state"
import classNames from "classnames"
import Overview from "./charity_view/Overview"
import Info from "./charity_view/Info"
import Financials from "./charity_view/Financials"
import { Tag } from "react-tag-autocomplete"

export type CharityListing = {
  id: string
  name: string
  description: string
  alt_description: string
  registration_number: string
  category: string
  charity_type: string
  status: string
  last_fetched_at: string
  address: string
  city: string
  state_province: string
  postal_code: string
  country: string
  url: string
  is_visible: boolean
  expenditures: number
  liquid_assets: number
  fundraising_ratio: number
  need_for_funding: number
  position_300plus: number

  annual_report_checked: boolean
  has_annual_report: boolean

  financial_statement_checked: boolean
  has_financial_statements: boolean

  exceeds_limits: string[]

  generated_tags: Tag[]
  custom_tags: Tag[]
  collection_tags: Tag[]
  geographic_scope_tags: Tag[]
  funds: string[]
  notes: string

  total_revenue: number,
  total_expenditures: number,
  funraising_expenditures: number,
  compensation_expenditures: number,
  period_end: string
  detailed_financials: any // Ugly, but it's pretty huge.
}

type Props = {
  charity: CharityListing
}

const CharityView = (props: Props) => {
  const [visibleTab, setVisibleTab] = useState<"overview"|"info"|"financials">("overview")

  const clearView = () => {
    State.update(s => {
      s.current_charity = null
      s.current_charity_id = null
    })

    removeFromQuery("current_charity_id")
  }

  const renderView = () => {
    return (
      <div className="admin-grid__column">
        <div className="table-actions" >
          <div className="btn-group">
            <button onClick={clearView} type="button" className="btn btn-primary">
              Back to results
            </button>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{ props.charity.name }</h5>
            <h6 className="card-subtitle mb-2 text-muted">{props.charity.registration_number}</h6>
            <h6 className="card-subtitle mb-2 text-muted">{`${props.charity.address}, ${props.charity.city}, ${props.charity.state_province}, ${props.charity.postal_code}`}</h6>
            <p className="card-text">
              <a href={`https://www.canadahelps.org/en/explore/charities/?q=${props.charity.registration_number}`}>CanadaHelps</a>
              &nbsp;
              <a href={`https://apps.cra-arc.gc.ca/ebci/hacc/srch/pub/dsplyRprtngPrd?selectedCharityBn=${props.charity.registration_number}`}>CRA</a>
              &nbsp;
              <a href={props.charity.url}>{props.charity.url}</a>
            </p>
          </div>

          <div className="row">
            <div className="col">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a className={classNames("nav-link", { active: visibleTab == "overview" })} onClick={() => setVisibleTab("overview")}>Overview</a>
                </li>
                <li className="nav-item">
                  <a className={classNames("nav-link", { active: visibleTab == "info" })} onClick={() => setVisibleTab("info")}>Info</a>
                </li>
                <li className="nav-item">
                  <a className={classNames("nav-link", { active: visibleTab == "financials" })} onClick={() => setVisibleTab("financials")}>Financials</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col">
              {
                visibleTab === "overview"
                &&
                <Overview charity={props.charity} />
              }

              {
                visibleTab === "info"
                &&
                <Info charity={props.charity} />
              }

              {
                visibleTab === "financials"
                &&
                <Financials charity={props.charity} />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (props.charity) {
    return renderView()
  } else {
    return (
      <div className="admin-charity-details">Loading</div>
    )
  }
}

export default CharityView

  // < div className = "tab-content" >
  //         <div className="tab-pane show active" id="overview" role="tabpanel" aria-labelledby="overviewtab">

  //           <div className="card mt-4 mb-4">
  //             <div className="card-body">
  //               <h6 className="mb-3">
  //                 Funds
  //               </h6>

  //               <div className="admin-tags-wrapper">
  //                 <% if @funds.blank? %>
  //                 <em>None</em>
  //                 <% end %>

  //                 <%= @funds&.map {| fund | link_to fund.name, admin_fund_path(fund), class: "give-pill" }&.join(", ")&.html_safe%>
  //               </div>
  //             </div>
  //           </div>

  //           <div className="card mt-4 mb-4">
  //             <div className="card-body">
  //               <h6 className="mb-3">
  //                 Geographic scope
  //               </h6>

  //               <div className="admin-tags-wrapper">
  //                 <%= react_component "TagAutocomplete", {listing_id: @listing.id, context: "geographic_scope", tags: @listing.geographic_scope_tags.map{| t | { id: t.id, name: t.name }} } %>
  //               </div>
  //             </div>
  //           </div>

  //           <div className="admin-2col mt-4 mb-3">
  //             <div className="card">
  //               <div className="card-body">
  //                 <h6 className="mb-3">
  //                   Tags
  //                 </h6>
  //                 <div className="admin-tags-wrapper">
  //                   <% @listing.generated_tags.each do |tag| %>
  //                   <div className="give-pill"><%= tag.name %></div>
  //                   <% end %>

  //                   <%= react_component "TagAutocomplete", {listing_id: @listing.id, context: "custom", tags: @listing.custom_tags.map{| t | { id: t.id, name: t.name }} } %>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="card">
  //               <div className="card-body">
  //                 <h6 className="mb-3">
  //                   Collections
  //                 </h6>
  //                 <div className="admin-tags-wrapper">
  //                   <%= react_component "TagAutocomplete", {listing_id: @listing.id, context: "collection", tags: @listing.collection_tags.map{| t | { id: t.id, name: t.name }} } %>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>

  //           <div className="card mt-4 mb-3">
  //             <div className="card-body">
  //               <h6 className="mb-3">Notes</h6>
  //               <%= form_with model: @listing, url: admin_charity_listing_path, method: :put, local: false do |f| %>
  //               <div className="form-group">
  //                 <%= f.rich_text_area :notes %>
  //                 <div className="small muted">Changed notes will auto-save.</div>
  //               </div>

  //               <script>
  //                 var debounce = function (fn, delay = 250) {
  //                   let timeoutID

  //                 return function () {
  //                   clearTimeout(timeoutID)

  //                 const args = arguments
  //                 timeoutID = setTimeout(() => fn(...args), delay)
  //               }
  //             }

  //                 $('trix-editor').on('trix-change', debounce(function () {
  //                   $.rails.fire($('trix-editor').closest("form")[0], "submit")
  //                 }))
  //               </script>
  //               <% end %>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="tab-pane" id="info" role="tabpanel" aria-labelledby="infotab">
  //           <div className="card mt-4">
  //             <div className="card-body">
  //               <h6 className="mb-3">Financials</h6>
  //               <div className="admin-charity-details__data">
  //                 <div className="admin-data">
  //                   <span className="admin-data__label">
  //                     Total revenue
  //                   </span>
  //                   <span className="admin-data__value">
  //                     <%= number_to_currency(@listing.row4700) %>
  //                   </span>
  //                 </div>
  //                 <div className="admin-data">
  //                   <span className="admin-data__label">
  //                     Total expenditures
  //                   </span>
  //                   <span className="admin-data__value">
  //                     <%= number_to_currency @listing.row5100 %>
  //                   </span>
  //                 </div>
  //                 <div className="admin-data">
  //                   <span className="admin-data__label">
  //                     Liquid assets
  //                   </span>
  //                   <span className="admin-data__value">
  //                     <%= number_to_currency @listing.liquid_assets %>
  //                   </span>
  //                 </div>
  //                 <div className="admin-data">
  //                   <span className="admin-data__label">
  //                     Fundraising expenditures
  //                   </span>
  //                   <span className="admin-data__value">
  //                     <%= number_to_currency @listing.row5020 %>
  //                   </span>
  //                 </div>
  //                 <div className="admin-data">
  //                   <span className="admin-data__label">
  //                     Compensation expenditures
  //                   </span>
  //                   <span className="admin-data__value">
  //                     <%= number_to_currency @listing.row4880 %>
  //                   </span>
  //                 </div>
  //                 <div className="admin-data">
  //                   <span className="admin-data__label">
  //                     Fundraising ratio
  //                   </span>
  //                   <span className="admin-data__value">
  //                     <%= number_to_percentage(@listing.fundraising_ratio * 100.0) %>
  //                   </span>
  //                 </div>
  //                 <div className="admin-data">
  //                   <span className="admin-data__label">
  //                     $300K+ staff
  //                   </span>
  //                   <span className="admin-data__value">
  //                     <%= @listing.position_300plus %>
  //                   </span>
  //                 </div>
  //                 <div className="admin-data">
  //                   <span className="admin-data__label">
  //                     Years of funding
  //                   </span>
  //                   <span className="admin-data__value">
  //                     <%= @listing.need_for_funding.round(2) %>
  //                   </span>
  //                 </div>
  //               </div>
  //               <p className="mt-3 mb-0">
  //                 <small>For the period ending on <%= @listing.period_end.to_formatted_s(:long) %></small>
  //               </p>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="tab-pane" id="data" role="tabpanel" aria-labelledby="datatab">
  //           <div className="card mt-4">
  //             <table className="table">
  //               <thead>
  //                 <tr>
  //                   <th>Form code</th>
  //                   <th>Amount</th>
  //                   <th>Description</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <% @listing.detailed_financials.to_a.sort_by{| a | a[0]}.each do |form_code, info| %>
  //                 <% next unless form_code.present? %>
  //                 <tr>
  //                   <td>
  //                     <code>
  //                       <%= form_code %>
  //                     </code>
  //                   </td>
  //                   <td>
  //                     <%= number_to_currency info["amount"] %>
  //                   </td>
  //                   <td>
  //                     <%= info["description"] %>
  //                   </td>
  //                 </tr>
  //                 <% end %>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //       </div >
