import React from 'react'
import 'react-dates/initialize'

import moment from 'moment'

import { DateRangePicker } from 'react-dates'

type State = {
  focusedInput: HTMLInputElement

  firstDate: moment.Moment
  lastDate: moment.Moment

  startDate: moment.Moment
  endDate: moment.Moment

  isNarrowScreen: boolean
  fullscreen: boolean
}

type Props = {
  label: string
  first_date: number
  last_date: number

  start_date: number
  end_date: number

  attribute_name: string
  error?: string
}

class DatePickerInput extends React.Component<Props, State> {
  componentContainer = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)

    const firstDate = moment.unix(this.props.first_date)
    const lastDate = moment.unix(this.props.last_date)

    const startDate = moment.unix(this.props.start_date)
    const endDate = moment.unix(this.props.end_date)

    this.state = {
      firstDate: firstDate,
      lastDate: lastDate,
      startDate: startDate,
      endDate: endDate,
      focusedInput: null,
      isNarrowScreen: this.getIsNarrow(),
      fullscreen: this.getNeedsFullscreen()
    }

    window.addEventListener("orientationchange", this.updateLayout.bind(this))
    window.addEventListener("resize", this.updateLayout.bind(this))
  }

  submitChanges() {
    let event = new Event('submit', { bubbles: true })

    this.checkCustom()

    setTimeout(() => {
      this.componentContainer.current?.closest('form').dispatchEvent(event)
    }, 150)
  }

  getIsNarrow(): boolean {
    return window.matchMedia('(max-width: 500px)').matches
  }

  getNeedsFullscreen(): boolean {
    return window.matchMedia('(max-width: 767px)').matches
  }

  updateLayout() {
    let narrow = this.getIsNarrow()
    let fullscreen = this.getNeedsFullscreen()

    this.setState({
      isNarrowScreen: narrow,
      fullscreen: fullscreen
    })
  }

  checkCustom () {
    const customDateInput = this.componentContainer.current?.closest('form').querySelector("#filter_date_custom");
    (customDateInput as HTMLInputElement).checked = true
  }

  render() {
    return (
      <div ref={this.componentContainer}>
        <div className="give-form-group__input">
          <DateRangePicker
            minimumNights={0}
            isOutsideRange={() => false}

            displayFormat={"MM-DD-YYYY"}

            startDate={this.state.startDate}
            startDateId="filter[start_date]"

            endDate={this.state.endDate}
            endDateId="filter[end_date]"

            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}

            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => {
              this.setState({ focusedInput })
              this.checkCustom()
            }}

            onClose={() => { this.submitChanges() }}
          />
        </div>

        {
          this.props.error
          &&
          <span className="give-error">{this.props.error}</span>
        }
      </div>
    )
  }
}

export default DatePickerInput