import React, { useState, useRef, useMemo } from 'react'
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe, SetupIntent, Stripe, StripeCardElementChangeEvent, StripeElements, StripeError } from '@stripe/stripe-js';

const CARD_OPTIONS = {
  iconStyle: 'solid' as 'solid' | 'default',
  style: {
    base: {
      iconColor: '#A7AFAF',
      color: '#3E4C4B',
      fontWeight: '500',
      fontFamily: 'Basier Circle, sans-serif',
      fontSize: '14px',
      border: '1px solid #DBE1E0',
      padding: "15",
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#60807E' },
      '::placeholder': { color: '#A7AFAF' },
    },
    invalid: {
      iconColor: '#EF8181',
      color: '#EF8181',
    },
  },
}

type Props = {
  stripeKey: "tech" | "foundation"
  onError: (error: StripeError) => void
  onSuccess: (setupIntent: SetupIntent) => void
  onLoading?: (loading: boolean) => void
}

const CreditCardSetupControl = (props: Props) => {
  const stripe = useMemo(() => {
    return loadStripe(props.stripeKey == "tech" ? (window as any).Give.techPublicKey : (window as any).Give.foundationPublicKey)
  },
    [props.stripeKey]
  )

  const [loading, setLoading] = useState(false)

  const cardChanged = (cardEl: StripeCardElementChangeEvent, stripe, elements) => {
    if (cardEl.complete) {
      createSetupIntent(stripe, elements)
    }
  }

  const createSetupIntent = async (stripe, elements) => {
    setLoading(true)
    props.onLoading && props.onLoading(true)
    const cardElement = elements.getElement(CardElement)

    const clientSecretResponse = await fetch(
      '/settings/payment_methods/client_secret',
      {
        method: 'post',
        body: JSON.stringify({ key_type: props.stripeKey }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)?.content
        }
      }
    )
    const response = await clientSecretResponse.json()

    const { error, setupIntent } = await stripe.confirmCardSetup(response.client_secret, {
      payment_method: {
        card: cardElement
      }
    })

    if (error) {
      console.log('[CreditCardInput][Error]', error)
      props.onError(error)
    } else {
      props.onSuccess(setupIntent)
    }

    setLoading(false)
    props.onLoading && props.onLoading(false)
  }

  return (
    <Elements stripe={stripe}>
      <ElementsConsumer>
        {
          ({ elements, stripe }) => (
            <CardElement options={{ ...CARD_OPTIONS, disabled: loading }} onChange={(cardEl) => cardChanged(cardEl, stripe, elements)}  />
          )
        }
      </ElementsConsumer>
    </Elements>
  )
}

export default CreditCardSetupControl