import React, { useMemo } from "react"
import { CharityListing } from "../CharityView"

type Props = {
  charity: CharityListing
}

const Financials = (props: Props) => {
  const currencyFormatter = useMemo(() => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }), [])

  const formatCurrency = (amount: number) => {
    if (amount !== null) {
      return currencyFormatter.format(amount)
    } else {
      return "-"
    }
  }

  return (
    <div className="card mt-4">
      <table className="table">
        <thead>
          <tr>
            <th>Form code</th>
            <th>Amount</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(props.charity.detailed_financials).map((key) => {
              return (
                <tr key={key}>
                  <td>
                    <code>
                      { key }
                    </code>
                  </td>
                  <td>
                    { formatCurrency(props.charity.detailed_financials[key]["amount"]) }
                  </td>
                  <td>
                    { props.charity.detailed_financials[key]["description"] }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default Financials