import React, { useEffect, useState } from 'react'

type Props = {
  text: string
  id?: string
  savedText?: string
  disabled?: boolean
  loading?: boolean
  onClick?: () => void
  className?: string
}

const SubmitButton = (props: Props) => {
  const text = props.text ?? "Continue"
  const [loading, setLoading] = useState(false)
  const [savedText, setSavedText] = useState<string|null>(props.savedText)

  useEffect(() => {
    const timeout = setTimeout(() => setSavedText(null), 2000)

    return () => clearTimeout(timeout)
  }, [props.savedText])

  const onSubmit = (event) => {
    // Chrome seems to require this timeout here.
    // If we set loading synchronously, Chrome won't
    // submit the containing <form>, wtf?

    if (props.onClick) {
      event.preventDefault()
      props.onClick()
    }

    setTimeout(() => {
      setLoading(true)
    })
  }

  const isLoading = () => {
    return props.loading || loading
  }

  return (
    <>
      <button
        id={props.id}
        type="submit"
        disabled={props.disabled || isLoading()}
        className={`give-button ${isLoading() && 'give-button--loading'} ${props.className}`}
        onClick={onSubmit}
      >
        {isLoading() ? '' : text}
      </button>

      {
        savedText
        &&
        <div className="save-notice">{props.savedText}</div>
      }
    </>
  )
}

export default SubmitButton