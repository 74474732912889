import React from 'react'
import InputRange from 'react-input-range'

import { useDebouncedCallback } from 'use-debounce'
import State from './state'
import AthenaClient from './AthenaClient'
import { removeFromQuery } from './mergeIntoQuery'

type SearchFormProps = {
  collections: string[]
  geographic_scopes: string[]
}

const SearchForm = (props: SearchFormProps) => {
  const searchOnLoad = State.useState(s => s.searchOnLoad)
  const isSearching = State.useState(s => s.isSearching)
  const timeElapsed = State.useState(s => s.lastSearchTime)
  const resultsCount = State.useState(s => s.results.length)
  const searchParams = State.useState(s => s.search)

  // useEffect(() => {
  //   submit()
  // }, [
  //   searchParams.collection_name,
  //   searchParams.geographic_scope,
  //   searchParams.state_province,
  //   searchParams.expenditures_min,
  //   searchParams.expenditures_max,
  //   searchParams.include_position_300plus,
  //   searchParams.hide_without_website,
  //   searchParams.has_annual_report,
  //   searchParams.has_financial_statements
  // ])

  const submit = async () => {
    const start = performance.now()

    State.update(s => {
      s.isSearching = true
      // If we're currently viewing a charity and they execute a search, go back to the results table
      s.current_charity = null
      s.current_charity_id = null
      removeFromQuery("current_charity_id")
    })

    const results = await AthenaClient.search(searchParams)

    State.update(s => {
      s.searchOnLoad = false
      s.results = results
      s.isSearching = false
      s.selectedRowIds = []
    })

    setTimeout(() => {
      const time = performance.now() - start
      State.update(s => {
        s.lastSearchTime = `${time}ms`
      })
    })
  }

  if (searchOnLoad) submit()

  return (
    <form className="search-form">
      <fieldset disabled={isSearching}>
        <div className="form-group">
          <label className="d-flex align-items-end">
            <div>Search for</div>
            <div className="flex-grow-1 text-right text-muted text-small">{resultsCount} results</div>
          </label>

          <div className="form-group">
            <input autoFocus={true}
                  placeholder="Type something"
                  className="form-control"
                  type="text"
                  defaultValue={searchParams.query}
                  onChange={(e) => State.update(s => { s.search.query = e.target.value })}
                  onKeyPress={(e) => e.key === "Enter" && submit()}
            />
            <small className="form-text text-muted">
              Searches in name, tags, and description.<br />
              Exact phrases "in quotes"<br />
              Exclude with !, ex. !youth
            </small>
          </div>

          <div className="form-group">
            <label htmlFor="search_in">City Fund Search</label>
            <select
              className="custom-select form-control"
              id="city_fund_search"
              value={searchParams.city_fund_search}
              onChange={(e) => State.update((s) => { s.search.city_fund_search = e.target.value })}
            >
              <option value="all" key="all_cities">All cities</option>
              <option value="Toronto" key="toronto">Toronto</option>
              <option value="Vancouver" key="vancouver">Vancouver</option>
              <option value="Calgary" key="calgary">Calgary</option>
              <option value="Edmonton" key="edmonton">Edmonton</option>
            </select>

            <small className="form-text text-muted">
              This restricts the results to our pre-selected set of postal codes for a city fund.
            </small>
          </div>

          <div className="form-group">
            <label htmlFor="search_in">Search in</label>
            <select
              className="custom-select form-control"
              id="search_in"
              value={searchParams.collection_name}
              onChange={(e) => State.update((s) => { s.search.collection_name = e.target.value })}
            >
              <option value="visible" key="visible">Visible</option>
              <option value="hidden" key="hidden">Hidden</option>
              <option value="all" key="all">All charities</option>
              <option disabled={true} value="blank-line" key="blank">Collection:</option>
              {
                props.collections.map((name) => <option value={name} key={name}>{ name }</option>)
              }
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="geographic_scope">Geographic impact scope</label>
            <select
              className="custom-select form-control"
              id="geographic_scope"
              value={searchParams.geographic_scope}
              onChange={(e) => State.update((s) => { s.search.geographic_scope = e.target.value })}
            >
              <option value=""></option>
              {
                props.geographic_scopes.map((name) => <option value={name} key={name}>{name}</option>)
              }
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="state_province">Province</label>

            <select
              id="state_province"
              className="custom-select form-control"
              value={searchParams.state_province}
              onChange={(e) => State.update(s => { s.search.state_province = e.target.value })}
            >
              <option value="" label=""></option>
              <option value="AB">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="MB">Manitoba</option>
              <option value="NB">New Brunswick</option>
              <option value="NL">Newfoundland and Labrador</option>
              <option value="NT">Northwest Territories</option>
              <option value="NS">Nova Scotia</option>
              <option value="NU">Nunavut</option>
              <option value="ON">Ontario</option>
              <option value="PE">Prince Edward Island</option>
              <option value="PQ">Québec</option>
              <option value="SK">Saskatchewan</option>
              <option value="YT">Yukon</option>
            </select>
          </div>

          <div className="form-group">
            <label className="d-flex align-items-end">
              <div>Expenditures</div>
            </label>

            <div className="d-flex">
              <div className="mr-1 input-small">
                <div className="form-group">
                  <label className="sr-only" htmlFor="expenditures_min">Expenditures min</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>

                    <input
                      value={searchParams.expenditures_min}
                      className="form-control"
                      type="text"
                      id="expenditures_min"
                      onChange={(e) => State.update((s) => { s.search.expenditures_min = parseInt(e.target.value, 10) || 0 })}
                    />
                  </div>
                </div>
              </div>
              <div className="ml-1 input-small">
                <div className="form-group">
                  <label className="sr-only" htmlFor="expenditures_max">Expenditures max</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>

                    <input
                      value={searchParams.expenditures_max}
                      className="form-control"
                      type="text"
                      id="expenditures_max"
                      onChange={(e) => State.update((s) => { s.search.expenditures_max = parseInt(e.target.value, 10) || 0 })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="d-flex align-items-end">
              <div>Fundraising ratio</div>
            </label>

            <InputRange
              formatLabel={value => `${value}%`}
              minValue={0}
              maxValue={100}
              value={{
                min: searchParams.fundraising_ratio_min,
                max: searchParams.fundraising_ratio_max
              }}
              onChange={(value) => State.update((s) => {
                s.search.fundraising_ratio_min = (value as any).min
                s.search.fundraising_ratio_max = (value as any).max
              })}
              onChangeComplete={useDebouncedCallback(submit, 100)}
            />
          </div>

          <div className="form-group">
            <label className="d-flex align-items-end">
              <div>Years of funding</div>
            </label>

            <InputRange
              minValue={0}
              maxValue={10}
              value={{
                min: searchParams.years_of_funding_min,
                max: searchParams.years_of_funding_max
              }}
              onChange={(value) => State.update((s) => {
                s.search.years_of_funding_min = (value as any).min
                s.search.years_of_funding_max = (value as any).max
              })}
              onChangeComplete={useDebouncedCallback(submit, 100)}
            />
          </div>

          <div className="form-group">
            <fieldset className="form-group">
              <div className="custom-control custom-checkbox">
                <input name="include_position_300plus" type="hidden" value="false" />
                <input
                  className="custom-control-input"
                  type="checkbox"
                  defaultChecked={searchParams.include_position_300plus}
                  defaultValue="true"
                  name="include_position_300plus"
                  id="include_position_300plus"
                  onChange={(e) => State.update((s) => { s.search.include_position_300plus = e.target.checked })}
                />
                <label className="custom-control-label" htmlFor="include_position_300plus">
                  Show with $300k+ staff
                </label>
              </div>
            </fieldset>
          </div>

          <div className="form-group">
            <fieldset className="form-group">
              <div className="custom-control custom-checkbox">
                <input name="hide_without_website" type="hidden" value="false" />
                <input
                  className="custom-control-input"
                  type="checkbox"
                  defaultChecked={searchParams.hide_without_website}
                  defaultValue="true"
                  name="hide_without_website"
                  id="hide_without_website"
                  onChange={(e) => State.update((s) => { s.search.hide_without_website = e.target.checked })}
                />
                <label className="custom-control-label" htmlFor="hide_without_website">
                  Hide without website
                </label>
              </div>
            </fieldset>
          </div>

          <div className="form-group">
            <fieldset className="form-group">
              <div className="custom-control custom-checkbox">
                <input name="has_annual_report" type="hidden" value="false" />
                <input
                  className="custom-control-input"
                  type="checkbox"
                  defaultChecked={searchParams.has_annual_report}
                  defaultValue="true"
                  name="has_annual_report"
                  id="has_annual_report"
                  onChange={(e) => State.update((s) => { s.search.has_annual_report = e.target.checked })}
                />
                <label className="custom-control-label" htmlFor="has_annual_report">
                  Has annual report
                </label>
              </div>
            </fieldset>
          </div>

          <div className="form-group">
            <fieldset className="form-group">
              <div className="custom-control custom-checkbox">
                <input name="has_financial_statements" type="hidden" value="false" />
                <input
                  className="custom-control-input"
                  type="checkbox"
                  defaultChecked={searchParams.has_financial_statements}
                  defaultValue="true"
                  name="has_financial_statements"
                  id="has_financial_statements"
                  onChange={(e) => State.update((s) => { s.search.has_financial_statements = e.target.checked })}
                />
                <label className="custom-control-label" htmlFor="has_financial_statements">
                  Has financial statements
                </label>
              </div>
            </fieldset>
          </div>

          <div className="form-group">
            <input type="submit" name="commit" value={isSearching ? "Searching..." : "Search"} className="btn btn-primary btn" disabled={isSearching} onClick={submit} />
            {
              timeElapsed
              &&
              <small className="form-text text-muted">Last search took { timeElapsed }</small>
            }
          </div>
        </div>
      </fieldset>
    </form>
  )
}

export default SearchForm
