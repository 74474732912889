import React, { useEffect, useState } from "react"
import { CharityListing } from "../CharityView"
import classNames from "classnames"
import AthenaClient from "../AthenaClient"
import State, { toBoolean } from "../state"
import { useDebounce } from "use-debounce"
import TagAutocomplete from "../../TagAutocomplete"

type Props = {
  charity: CharityListing
}

const Overview = (props: Props) => {
  const [website, setWebsite] = useState(props.charity.url)

  const [notes, setNotes] = useState(props.charity.notes)
  const [debouncedNotes] = useDebounce(notes, 500)

  const [annualReportChecked, setAnnualReportChecked] = useState(props.charity.annual_report_checked)
  const [hasAnnualReport, setHasAnnualReport] = useState(props.charity.has_annual_report)

  const [financialStatementChecked, setFinancialStatementChecked] = useState(props.charity.financial_statement_checked)
  const [hasFinancialStatements, setHasFinancialStatements] = useState(props.charity.has_financial_statements)

  const saveListing = async () => {
    const charity = await AthenaClient.updateListing(props.charity.id, {
      url: website,
      annual_report_checked: annualReportChecked,
      has_annual_report: hasAnnualReport,
      financial_statement_checked: financialStatementChecked,
      has_financial_statements: hasFinancialStatements,
      notes: notes
    })
    State.update((s) => {
      s.current_charity = charity
    })
  }

  useEffect(
    () => { saveListing() },
    [debouncedNotes, annualReportChecked, hasAnnualReport, financialStatementChecked, hasFinancialStatements]
  )

  const statusBadgeClasses = classNames(
    "badge",
    {
      "badge-success": props.charity.status === "complete",
      "badge-warning": props.charity.status === "incomplete",
      "badge-danger": props.charity.status === "error",
    }
  )



  return (
    <div className="card mt-4 mb-4">
      <table className="table">
        <tbody>
          <tr>
            <td>
              Description
            </td>
            <td>
              { props.charity.description.replace("\n", "<br />") }
            </td>
          </tr>
          <tr>
            <td>
              CH Description
            </td>
            <td>
              { props.charity.alt_description.replace("\n", "<br />") }
            </td>
          </tr>
          <tr>
            <td>
              Website
            </td>
            <td>
              <div className="d-flex align-items-center">
                <input type="text" name="website" className="mr-2 give-input" defaultValue={props.charity.url} onChange={(e) => setWebsite(e.target.value)} />
                <button className="give-button give-button--secondary" onClick={saveListing}>Update website</button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              Documents & Transparency
            </td>
            <td>
              <div className="give-form-group">
                <div className="give-checkbox-group give-form-group">
                  <input className="give-checkbox give-checkbox" type="checkbox" checked={annualReportChecked} onChange={(e) => setAnnualReportChecked(e.target.checked)} name="annual_report_checked" id="annual_report_checked" />
                  <label className="give-checkbox-label give-label" htmlFor="annual_report_checked">The annual reports have been checked</label>
                </div>

                <label className="give-label">Has annual report</label>
                <div className="give-checkbox-group give-form-group">
                  <input className="give-checkbox give-checkbox" type="radio" checked={hasAnnualReport} onChange={(e) => { setHasAnnualReport(toBoolean(e.target.value)) }} value="1" name="has_annual_report" id="has_annual_report_true" />
                  <label className="give-checkbox-label give-label" htmlFor="has_annual_report_true">Yes</label>
                </div>

                <div className="give-checkbox-group give-form-group">
                  <input className="give-checkbox give-checkbox" type="radio" checked={!hasAnnualReport} onChange={(e) => { setHasAnnualReport(toBoolean(e.target.value)) }} value="0" name="has_annual_report" id="has_annual_report_false" />
                  <label className="give-checkbox-label give-label" htmlFor="has_annual_report_false">No</label>
                </div>
              </div>

              <div className="give-form-group">
                <div className="give-checkbox-group give-form-group">
                  <input className="give-checkbox give-checkbox" type="checkbox" checked={financialStatementChecked} onChange={(e) => setFinancialStatementChecked(e.target.checked)} name="financial_statements_checked" id="financial_statements_checked" />
                  <label className="give-checkbox-label give-label" htmlFor="financial_statements_checked">The financial statements been checked</label>
                </div>

                <label className="give-label">Has financial statements</label>
                <div className="give-checkbox-group give-form-group">
                  <input className="give-checkbox give-checkbox" type="radio" checked={hasFinancialStatements} onChange={(e) => setHasFinancialStatements(toBoolean(e.target.value))} value="1" name="has_financial_statements" id="has_financial_statements_true" />
                  <label className="give-checkbox-label give-label" htmlFor="has_financial_statements_true">Yes</label>
                </div>

                <div className="give-checkbox-group give-form-group">
                  <input className="give-checkbox give-checkbox" type="radio" checked={!hasFinancialStatements} onChange={(e) => setHasFinancialStatements(toBoolean(e.target.value))} value="0" name="has_financial_statements" id="has_financial_statements_false" />
                  <label className="give-checkbox-label give-label" htmlFor="has_financial_statements_false">No</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              Category
            </td>
            <td>
              { props.charity.url }
            </td>
          </tr>
          <tr>
            <td>
              Type
            </td>
            <td>
              { props.charity.charity_type }
            </td>
          </tr>
          <tr>
            <td>
              Status
            </td>
            <td>
              <span className={statusBadgeClasses}>
                { props.charity.status }
              </span>
            </td>
          </tr>
          <tr>
            <td>
              Last updated
            </td>
            <td>
              {isNaN(Date.parse(props.charity.last_fetched_at)) ? "-" : (new Intl.DateTimeFormat('en-US')).format(Date.parse(props.charity.last_fetched_at)) }
            </td>
          </tr>
        </tbody>
      </table>
      <div className="card mt-4 mb-4">
        <div className="card-body">
          <h6 className="mb-3">
            Funds
          </h6>

          <div className="admin-tags-wrapper">
            {
              props.charity.funds.length === 0
              ?
              <em>None</em>
              :
              props.charity.funds.map((fund: any) => {
                return (
                  <a className="give-pill" href={`/admin/funds/${fund.slug}/edit`} key={fund.slug}>{fund.name}</a>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="card mt-4 mb-4">
        <div className="card-body">
          <h6 className="mb-3">
            Geographic scope
          </h6>

          <div className="admin-tags-wrapper">
            <TagAutocomplete listing_id={props.charity.id} context="geographic_scope" tags={props.charity.geographic_scope_tags} />
          </div>
        </div>
      </div>

      <div className="admin-2col mt-4 mb-3">
        <div className="card">
          <div className="card-body">
            <h6 className="mb-3">
              Tags
            </h6>
            <div className="admin-tags-wrapper">
              {
                props.charity.custom_tags.map((tag) => {
                  return <div className="give-pill" key={tag.id}>{ tag.name }</div>
                })
              }

              <TagAutocomplete listing_id={props.charity.id} context="custom" tags={props.charity.custom_tags} />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h6 className="mb-3">
              Collections
            </h6>
            <div className="admin-tags-wrapper">
              <TagAutocomplete listing_id={props.charity.id} context="collection" tags={props.charity.collection_tags} />
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-4 mb-3">
        <div className="card-body">
          <h6 className="mb-3">Notes</h6>
          <div className="form-group">
            <textarea className="give-textarea" rows={7} defaultValue={props.charity.notes} onChange={(e: any) => setNotes(e.target.value)}/>
            <div className="small muted">Changed notes will auto-save.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview