import React from 'react'
import ReactTags, { Tag } from 'react-tag-autocomplete'
import Athena from './research/Athena'
import AthenaClient from './research/AthenaClient'

import "./styles.scss"

type Props = {
  listing_id: string
  context: "custom" | "collection" | "generated" | "geographic_scope"
  tags: Tag[]
}

type State = {
  tags: Tag[]
  suggestions: Tag[]
}

const debounce = function (fn, delay = 250) {
  let timeoutID

  return function () {
    clearTimeout(timeoutID)

    const args = arguments
    timeoutID = setTimeout(() => fn(...args), delay)
  }
}

function TagComponent({ tag, removeButtonText, onDelete }) {
  return (
    <div className="give-pill give-pill--highlight" title={removeButtonText} onClick={onDelete}>{tag.name}</div>
  )
}

class TagAutocomplete extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    console.log("TagAutocomplete")

    this.state = {
      tags: props.tags,
      suggestions: []
    }
  }

  onDelete = async (i) => {
    const tags = this.state.tags.slice(0)
    let removed_tags = tags.splice(i, 1)
    this.setState({ tags })

    try {
      let tags = await AthenaClient.removeTag(this.props.listing_id, this.props.context, removed_tags[0])
      this.setState({ tags })
    } catch (error) {
      console.error(error)
    }
  }

  onAddition = async (tag) => {
    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags })

    try {
      let tags = await AthenaClient.addTag(this.props.listing_id, this.props.context, tag)
      this.setState({ tags })
    } catch (error) {
      console.error(error)
    }
  }

  handleInputChange = debounce(async (query) => {
    if (query == "") {
      this.setState({ suggestions: [] })
      return
    }

    try {
      let tags = await AthenaClient.tagSuggestions(this.props.listing_id, this.props.context, query)
      this.setState({ suggestions: tags })
    } catch (error) {
      console.error(error)
    }
  })

  render() {
    return (
      <ReactTags
        tags={this.state.tags}
        suggestions={this.state.suggestions}
        onDelete={this.onDelete}
        onAddition={this.onAddition}
        onInput={this.handleInputChange}
        tagComponent={TagComponent}
        maxSuggestionsLength={25}
        placeholderText={"Type here to add..."}
        allowNew={true}
      />
    )
  }
}

export default TagAutocomplete