import React, { useEffect, useMemo, useState } from "react"
import { CharityListing } from "../CharityView"
import classNames from "classnames"
import AthenaClient from "../AthenaClient"
import State, { toBoolean } from "../state"

type Props = {
  charity: CharityListing
}

const Info = (props: Props) => {
  const [website, setWebsite] = useState(props.charity.url)

  const [annualReportChecked, setAnnualReportChecked] = useState(props.charity.annual_report_checked)
  const [hasAnnualReport, setHasAnnualReport] = useState(props.charity.has_annual_report)

  const [financialStatementChecked, setFinancialStatementChecked] = useState(props.charity.financial_statement_checked)
  const [hasFinancialStatements, setHasFinancialStatements] = useState(props.charity.has_financial_statements)

  const saveListing = async () => {

    console.log(hasAnnualReport);

    const charity = await AthenaClient.updateListing(props.charity.id, {
      url: website,
      annual_report_checked: annualReportChecked,
      has_annual_report: hasAnnualReport,
      financial_statement_checked: financialStatementChecked,
      has_financial_statements: hasFinancialStatements
    })
    State.update((s) => {
      s.current_charity = charity
    })
  }

  const currencyFormatter = useMemo(() => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }), [])

  useEffect(
    () => { saveListing() },
    [annualReportChecked, hasAnnualReport, financialStatementChecked, hasFinancialStatements]
  )

  const statusBadgeClasses = classNames(
    "badge",
    {
      "badge-success": props.charity.status === "complete",
      "badge-warning": props.charity.status === "incomplete",
      "badge-danger": props.charity.status === "error",
    }
  )

  const foo = (e: any) => {
    const val = toBoolean(e.target.value)
    console.log("hasAnnualReport", val)
    setHasAnnualReport(val)
  }

  const formatCurrency = (amount: number) => {
    if (amount !== null) {
      return currencyFormatter.format(amount)
    } else {
      return "-"
    }
  }

  return (
    <div className="card mt-4 mb-4">
      <div className="card mt-4">
        <div className="card-body">
          <h6 className="mb-3">Financials</h6>
          <div className="admin-charity-details__data">
            <div className="admin-data">
              <span className="admin-data__label">
                Total revenue
              </span>
              <span className="admin-data__value">
                {formatCurrency(props.charity.total_revenue) }
              </span>
            </div>
            <div className="admin-data">
              <span className="admin-data__label">
                Total expenditures
              </span>
              <span className="admin-data__value">
                {formatCurrency(props.charity.total_expenditures) }
              </span>
            </div>
            <div className="admin-data">
              <span className="admin-data__label">
                Liquid assets
              </span>
              <span className="admin-data__value">
                {formatCurrency(props.charity.liquid_assets)}
              </span>
            </div>
            <div className="admin-data">
              <span className="admin-data__label">
                Fundraising expenditures
              </span>
              <span className="admin-data__value">
                {formatCurrency(props.charity.funraising_expenditures)}
              </span>
            </div>
            <div className="admin-data">
              <span className="admin-data__label">
                Compensation expenditures
              </span>
              <span className="admin-data__value">
                {formatCurrency(props.charity.compensation_expenditures)}
              </span>
            </div>
            <div className="admin-data">
              <span className="admin-data__label">
                Fundraising ratio
              </span>
              <span className="admin-data__value">
                {props.charity.fundraising_ratio && props.charity.fundraising_ratio.toFixed(2) }
              </span>
            </div>
            <div className="admin-data">
              <span className="admin-data__label">
                $300K+ staff
              </span>
              <span className="admin-data__value">
                {props.charity.position_300plus}
              </span>
            </div>
            <div className="admin-data">
              <span className="admin-data__label">
                Years of funding
              </span>
              <span className="admin-data__value">
                {props.charity.need_for_funding.toFixed(2)}
              </span>
            </div>
          </div>
          <p className="mt-3 mb-0">
            <small>For the period ending on {isNaN(Date.parse(props.charity.period_end)) ? "-" : (new Intl.DateTimeFormat('en-US')).format(Date.parse(props.charity.period_end))} </small>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Info