import React, { useState } from 'react'
import CreditCardInput from "../CreditCardInput"
import SubmitButton from '../SubmitButton'

type PaymentMethod = {
  id: string
  default: boolean
  is_payment_method: boolean
  source_type: string
  brand: string
  last4: string
  exp_month: string
  status: string
  status_message: string
}

type Props = {
  is_core_plan: boolean
  subscriptionCard?: PaymentMethod
  donationCard?: PaymentMethod
}

const BillingMethodsForm = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const [showNewSubscriptionCard, setShowNewSubscriptionCard] = useState(!props.subscriptionCard)
  const [newSubscriptionCardId, setNewSubscriptionCardId] = useState<string>()

  const [showNewDonationCard, setShowNewDonationCard] = useState(!props.donationCard)
  const [newDonationCardId, setNewDonationCardId] = useState<string>()

  const subscriptionCardChanged = (e) => {
    setShowNewSubscriptionCard(e.target.value == "new_card")
  }

  const donationCardChanged = (e) => {
    setShowNewDonationCard(e.target.value == "new_card")
  }

  const disableButton = () => {
    return loading || (!newSubscriptionCardId && !newDonationCardId)
  }

  const renderSubscriptionPaymentMethod = () => {
    return (
      <div className="give-form-group">
        <label className="give-label">Payment method for subscription fee</label>
        <div className="give-form-group__input">
          <select onChange={subscriptionCardChanged} className="give-select" name="payment_methods[subscription_card_id]">
            {
              props.subscriptionCard
              &&
              <option value={props.subscriptionCard.id}>
                {props.subscriptionCard.brand} ending {props.subscriptionCard.last4}
              </option>
            }

            <option value="new_card">Add new payment method</option>
          </select>
        </div>
        {
          showNewSubscriptionCard
          &&
          <div className="give-stripe-input give-form-group__input">
            <CreditCardInput
              stripeKey={"tech"}
              onError={(error) => { setError(error.message) }}
              onLoading={(loading) => setLoading(loading)}
              onSuccess={(setupIntent) => { setNewSubscriptionCardId(setupIntent.payment_method) }}
            />
          </div>
        }

        {
          newSubscriptionCardId
          &&
          <input type="hidden" name="payment_methods[subscription_card_id]" value={newSubscriptionCardId} />
        }
      </div>
    )
  }

  return (
    <div className="billing-methods">
      { props.is_core_plan ? <></> : renderSubscriptionPaymentMethod() }

      <div className="give-form-group">
        <label className="give-label">Payment method for donations</label>
        <div className="give-form-group__input">
          <select onChange={donationCardChanged} className="give-select" name="payment_methods[donation_card_id]">
            {
              props.donationCard
              &&
              <option value={props.donationCard.id}>
                {props.donationCard.brand} ending {props.donationCard.last4}
              </option>
            }

            <option value="new_card">Add new payment method</option>
          </select>
        </div>
        {
          showNewDonationCard
          &&
          <div className="give-stripe-input give-form-group__input">
            <CreditCardInput
              stripeKey={"foundation"}
              onError={(error) => { setError(error.message) }}
              onLoading={(loading) => setLoading(loading)}
              onSuccess={(setupIntent) => { setNewDonationCardId(setupIntent.payment_method) }}
            />
          </div>
        }
        {
          newDonationCardId
          &&
          <input type="hidden" name="payment_methods[donation_card_id]" value={newDonationCardId} />
        }
      </div>

      {
        error
        &&
        <div className="give-form-group">
          <div className="give-error">
            {error}
          </div>
        </div>
      }

      <button
        type="submit"
        disabled={disableButton()}
        className={`donate__button give-button ${loading && 'give-button--loading'}`}
        onClick={() => setLoading(true)}
      >
        {loading ? '' : 'Save payment methods'}
      </button>
    </div>
  )
}

export default BillingMethodsForm