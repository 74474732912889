import React from 'react'
import { Cause, Fund, Company, DonationMatchPreview, PaymentMethod, Donation } from '../types';
import SubmitButton from "../components/SubmitButton"
type Step = 'amount' | 'checkout' | 'success'

type Props = {
  company_id: string
  cause_id: string
  fund_id: string
  company_donation_method: PaymentMethod
  with_donation_matching: boolean
}

type State = {
  company?: Company
  cause?: Cause
  fund?: Fund
  donationMatches?: DonationMatchPreview[]

  step: Step
  amount: number
  feeAmount?: number
  totalAmount?: number
  loading: boolean
  showAmountField: boolean
  changingAmount: boolean

  donation?: Donation

  error?: string
}

class CompanyOneTimeDonation extends React.Component<Props, State> {
  stripePromise: any
  stripeValue: any
  feePercentage: number = 0.02
  feeCents: number = 50
  minimumDonation: number = 500

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      step: 'amount',
      amount: 2000,
      showAmountField: false,
      changingAmount: false
    }

    setTimeout(() => {
      this.setAmount(this.state.amount)
      this.getCompany()
      this.getCause()
    })
  }

  async getCompany () {
    const response = await fetch(`/api/v2/companies/${this.props.company_id}`, { credentials: "same-origin" })
    const data = await response.json()

    this.setState({ company: data.company as Company})
  }

  async getCause () {
    const response = await fetch(`/api/v2/causes/${this.props.cause_id}`, { credentials: "same-origin" })
    const data = await response.json()

    this.setState({ cause: data.cause as Cause })
  }

  async previewDonationMatches() {
    if (this.props.with_donation_matching) {
      const response = await fetch(`/api/v2/donations/preview_one_time?amount=${this.state.amount}&fund_id=${this.props.fund_id}`, { credentials: "same-origin" })
      const data = await response.json()

      this.setState({ donationMatches: data as DonationMatchPreview[] })
    }
  }

  setAmount(amount: number) {
    if (Number.isNaN(amount)) {
      if (this.state.showAmountField) {
        // If the field is already showing, then we can show the error.
        this.setState({
          error: `The amount must be a number.`
        })
      } else {
        this.setState({
          showAmountField: true
        })
      }
    } else {
      if (amount >= this.minimumDonation) {
        let feeAmount = Math.round((amount * this.feePercentage) + this.feeCents)
        let totalAmount = amount + feeAmount

        this.setState({
          amount,
          feeAmount,
          totalAmount,
          error: null
        })
      } else {
        this.setState({
          error: `The minimum is $${this.minimumDonation / 100}`
        })
      }
    }
  }

  async submitDonation() {
    this.setState({ loading: true, error: null })

    try {
      const response = await fetch('/api/v2/donations', {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          donation: {
            amount: this.state.amount,
            fund_id: this.props.fund_id,
            payer_type: "company"
          }
        })
      })

      const data = await response.json()

      if (response.status === 201) {
        const donation = data.donation as Donation

        const params = new URLSearchParams(window.location.search)
        params.append("successful_donation_id", donation.id)

        window.location.search = params.toString()
      } else {
        const error = data.message
        this.setState({
          error: error,
          loading: false
        })
      }
    } catch (error) {
      console.log('[Error]', error)

      let message = 'There was an error :( Sorry!'

      if (error.response?.data?.message) {
        message = error.response.data.message
      } else {
        message = error.message
      }

      this.setState({
        error: message,
        loading: false
      })
    }
  }

  changeFund = (e) => {
    const fundId = e.target.value
    const params = new URLSearchParams()
    params.append("fund_id", fundId)

    window.location.search = params.toString()
  }

  goToCheckout (e) {
    e.preventDefault()

    this.setState({
      donationMatches: [],
      step: "checkout"
    })

    this.previewDonationMatches()
  }

  renderAmount () {
    return (
      <div className="donate">
        <form className="donate__step donate__step--amount" onSubmit={(e) => { this.goToCheckout(e) }}>
          <h2 className="give-card__title give-card__title--small">
            Donate
          </h2>
          <div className="give-form">
            <div className="give-form-group">
              <label className="give-label">
                Fund
              </label>
              <div className="give-form-group__input">
                <div className="give-select-two-line-wrapper">
                  <span className="give-select-two-line-wrapper__text">
                    {this.state.cause?.name}
                  </span>
                  <select className="give-select give-select--two-line" value={this.props.fund_id} onChange={this.changeFund}>
                    {
                      this.state.cause?.all_funds.map((fund) => {
                        return <option value={fund.id} key={fund.id}>{fund.city_name || "Canada"}</option>
                      })
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="give-form-group">
              <div className="give-label">Amount</div>
              { this.renderAmountSelector() }
            </div>

            <button
              disabled={!!this.state.error}
              className="give-button give-button--full-width"
              type="submit"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    )
  }

  renderCheckout () {
    return (
      <div className="donate">
        <div className="donate__step donate__step--amount">
          <h2 className="give-card__title give-card__title--small">
            Review your donation
          </h2>
          <div className="give-form">

            <div className="donate__total">
              <div className="donate__total__amount">
                ${(this.state.totalAmount / 100.0).toFixed(2)}
              </div>
            </div>

            <div className="donate__breakdown">
              <div className="donate__breakdown__group">
                <span className="donate__breakdown__label">
                  Your donation
                </span>
                <span className="donate__breakdown__value">
                  ${(this.state.amount / 100.0).toFixed(2)}
                </span>
              </div>

              <div className="donate__breakdown__group">
                <span className="donate__breakdown__label">
                  Processing fee
                </span>
                <span className="donate__breakdown__value">
                  ${(this.state.feeAmount / 100.0).toFixed(2)}
                </span>
              </div>

              <div className="donate__breakdown__group">
                <span className="donate__breakdown__label">
                  Platform fee
                </span>
                <span className="donate__breakdown__value">
                  None
                </span>
              </div>
            </div>

            <p className="donate__hint">
              Your donation will be charged to your {this.props.company_donation_method.brand} ending in {this.props.company_donation_method.last4} and will
              be split equally between all the charities in {this.state.cause?.name} Fund {this.state.fund?.city_name || "Canada"}.
            </p>

            <SubmitButton
              disabled={!!this.state.error}
              onClick={() => this.submitDonation()}
              className="give-button--full-width"
              text="Donate"
            />

            {
              this.state.error
              &&
              <span className='give-error'>{this.state.error}</span>
            }

            <a className='donate__go-back' onClick={() => this.setState({ step: "amount" })}>Go back</a>
          </div>
        </div>
      </div>
    )
  }

  renderSuccess () {
    return (
      <div className="donate">
        <h2 className="give-card__title give-card__title--small">
          Donation complete
        </h2>
        <div className="give-form">
          <p className="donate__hint">

          </p>
        </div>
      </div>
    )
  }

  render () {
    if (this.state.step === "amount") {
      return this.renderAmount()
    } else if (this.state.step === "checkout") {
      return this.renderCheckout()
    } else if (this.state.step === "success") {
      return this.renderSuccess()
    }
  }

  renderAmountSelector () {
    if (this.state.showAmountField) {
      return (
        <div className="give-form-group__input">
          <input
            type="text"
            defaultValue={this.state.amount / 100.0}
            className="give-input give-input--dollar"
            onChange={(e) => this.setAmount(100 * parseFloat(e.target.value))}
          />
          {
            this.state.error
            &&
            <span className='give-error'>{this.state.error}</span>
          }
        </div>
      )
    } else {
      this.focusSelector("#amount")

      return (
        <div className="give-form-group__input">
          <select
            name="amount"
            id="amount"
            defaultValue={this.state.amount}
            className="donate__amount give-select-large"
            onChange={(e) => this.setAmount(parseFloat(e.target.value))}>
            <option value="1000">$10</option>
            <option value="2000">$20</option>
            <option value="5000">$50</option>
            <option value="10000">$100</option>
            <option value="20000">$200</option>
            <option value="other">Other</option>
          </select>
        </div>
      )
    }
  }

  focusSelector(selector: string) {
    setTimeout(() => {
      (document.querySelector(selector) as HTMLInputElement)?.focus()
    }, 100)
  }
}

export default CompanyOneTimeDonation