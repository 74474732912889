import React, { useEffect } from 'react'
import SearchForm from './SearchForm'
import ResultsTable from './ResultsTable'

import State from './state'
import CharityView from './CharityView'
import AthenaClient from './AthenaClient'

type AthenaProps = {
  collections: string[]
  geographic_scopes: string[]
}

const Athena = (props: AthenaProps) => {
  const query = State.useState(s => s.search.query)
  const results = State.useState(s => s.results)
  const collections = State.useState(s => s.collections)
  const geographic_scopes = State.useState(s => s.geographic_scopes)
  const current_charity = State.useState(s => s.current_charity)
  const current_charity_id = State.useState(s => s.current_charity_id)
  const searchParams = State.useState(s => s.search)

  useEffect(() => {
    State.update(s => {
      s.collections = props.collections
      s.geographic_scopes = props.geographic_scopes
    })
  }, [])

  useEffect(() => {
    const getListing = async () => {
      const charity = await AthenaClient.getListing(current_charity_id)
      State.update((s) => {
        s.current_charity = charity
      })
    }

    const getSearchResults = async () => {
      const results = await AthenaClient.search(searchParams)
      State.update(s => {
        s.searchOnLoad = false
        s.results = results
        s.isSearching = false
        s.selectedRowIds = []
      })
    }

    if (current_charity_id) getListing()

    if (current_charity_id && query) getSearchResults()

  }, [])

  const renderLoading = () => {
    return (
      <div className="admin-grid__column">
        <div className="admin-grid--loading">
          Loading...
        </div>
      </div>
    )
  }

  return (
    <div className="athena">
      <div className="row mt-4 admin-grid admin-grid--fluid">
        <div className="admin-grid__column">
          <div className="card">
            <div className="card-body">
              <SearchForm collections={collections} geographic_scopes={geographic_scopes} />
            </div>
          </div>
        </div>

        { !current_charity_id ? <ResultsTable results={results} collections={collections} /> : '' }
        { current_charity_id && !current_charity ? renderLoading() : '' }
        { current_charity_id && current_charity ? <CharityView charity={current_charity} /> : '' }
      </div>
    </div>
  )
}

export default Athena