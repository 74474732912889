declare const axios

import React from 'react'
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Cause, Fund } from '../types';

type Step = 'amount' | 'personal_info' | 'message' | 'subtotal' | 'checkout'

type Props = {
  cause: Cause
  fund: Fund
}

type State = {
  step: Step
  amount: number
  totalAmount?: number
  feeAmount?: number
  showAmountField: boolean
  firstName: string
  lastName: string
  message: string
  email: string
  color: number
  showName: boolean
  showAmount: boolean
  changingAmount: boolean
  loading: boolean
  cardComplete: boolean

  amountError?: string
  emailError?: string
  paymentError?: string
}

class OneTimeDonation extends React.Component<Props, State> {
  stripePromise: any
  stripeValue: any
  feePercentage: number = 0.02
  feeCents: number = 50
  minimumDonation: number = 500

  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      step: 'amount',
      amount: 2000,
      showAmountField: false,
      firstName: '',
      lastName: '',
      message: '',
      email: '',
      color: 0,
      showName: true,
      showAmount: true,
      changingAmount: false,
      cardComplete: false
    }

    // @ts-ignore
    this.stripePromise = loadStripe(window.Give.foundationPublicKey)

    setTimeout(() => {
      this.setAmount(this.state.amount)
    })
  }

  gotoStep (step: Step, e?: any) {
    e?.preventDefault()
    this.setState({ step })
  }

  setAmount (amount: number) {
    if (Number.isNaN(amount)) {
      if (this.state.showAmountField) {
        // If the field is already showing, then we can show the error.
        this.setState({
          amountError: `The amount must be a number.`
        })
      } else {
        this.setState({
          showAmountField: true
        })
      }
    } else {
      if (amount >= this.minimumDonation) {
        let feeAmount = Math.round((amount * this.feePercentage) + this.feeCents)
        let totalAmount = amount + feeAmount

        this.setState({
          amount,
          feeAmount,
          totalAmount,
          amountError: null
        })
      } else {
        this.setState({
          amountError: `The minimum is $${this.minimumDonation / 100}`
        })
      }
    }
  }

  setMessage (message: string) {
    message = message.substring(0, 140)
    this.setState({ message })
  }

  cardChanged(cardEl) {
    this.setState({ cardComplete: cardEl.complete })
  }

  validPersonalInfo () {
    return this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.validEmail()
  }

  validEmail () {
    return /^.+@.+\..+$/.test(this.state.email)
  }

  async getToken () {
    const response = await axios.post('/api/v2/one_time_payments/token', { email: this.state.email })
    const body = response.data
    return body
  }

  async submitDonation (elements, stripe) {
    this.setState({ loading: true, paymentError: null })

    const {token, customer_id} = await this.getToken()
    const cardElement = elements.getElement(CardElement)

    const { error, setupIntent } = await stripe.confirmCardSetup(token, {
      payment_method: {
        card: cardElement
      }
    })

    if (error) {
      console.log('[Error]', error)
      this.setState({
        paymentError: error.message,
        loading: false
      })
    } else {
      try {
        const response = await fetch('/api/v2/one_time_payments', {
          method: "POST",
          credentials: "same-origin",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            donation: {
              email: this.state.email,
              customer_id: customer_id,
              fund_id: this.props.fund.id,
              amount: this.state.amount,
              payment_method_id: setupIntent.payment_method,
              message: {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                body: this.state.message,
                show_name: this.state.showName,
                show_amount: this.state.showAmount,
                color: this.state.color
              }
            }
          })
        })

        const data = await response.json()
        const payment_id = data.payment_id
        window.location.href = `/donation-success?payment_id=${payment_id}`

      } catch (error) {
        console.log('[Error]', error)

        let message = 'There was an error :( Sorry!'
        error.response.data.message

        if (error?.response?.data?.message) {
          message = error.response.data.message
        } else {
          message = error.message
        }

        this.setState({
          paymentError: message,
          loading: false
        })
      }
    }
  }

  changeFund = (e) => {
    const fundId = e.target.value
    const params = new URLSearchParams()
    params.append("fund_id", fundId)

    window.location.search = params.toString()
  }

  renderAmountStep () {
    return (
      <form className="donate__step donate__step--amount" onSubmit={(e) => this.gotoStep('personal_info', e)} onKeyPress={(e) => e.key === "Enter" && this.gotoStep('personal_info', e)}>
        <h2 className="give-card__title give-card__title--small">
          Donate
        </h2>
        <div className="give-form">
          <div className="give-form-group">
            <label className="give-label">
              Fund
            </label>
            <div className="give-form-group__input">
              <div className="give-select-two-line-wrapper">
                <span className="give-select-two-line-wrapper__text">
                  { this.props.cause.name} Fund
                </span>
                <select className="give-select give-select--two-line" value={this.props.fund.id} onChange={this.changeFund}>
                  {
                    this.props.cause.all_funds.map((fund) => {
                      return <option value={fund.id} key={fund.id}>{ fund.city_name || "Canada" }</option>
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="give-form-group">
            <div className="give-label">
              Amount
            </div>

            {
              this.renderAmountSelector()
            }

          </div>

          <button
            disabled={!!this.state.amountError}
            className="give-button give-button--full-width"
            type="submit"
          >
            Donate
          </button>

          <p className="donate__hint">
            Your donation will be split equally between all the charities in {this.props.cause.name} Fund { this.props.fund.city_name || "Canada" }.
          </p>

          <p className="donate__hint">
            Want to make this a recurring donation? Download <a href="http://withgive.app.link/">Give for iOS and Android</a>.
          </p>
        </div>
      </form>
    )
  }

  renderPersonalInfo () {
    return (
      <div className="donate__step donate__step--message">
        <h2 className="give-card__title give-card__title--small">
          About you
        </h2>
        <form className="give-form" onSubmit={(e) => this.validPersonalInfo() && this.gotoStep('message', e)}>
          <div className="give-form-group">
            <label className="give-label">
              Name
            </label>
            <div className="give-form-group__inline-wrapper">
              <div className="give-form-group__input">
                <input
                  type="text"
                  className="give-input"
                  placeholder="Jane"
                  value={this.state.firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                />
              </div>
              <div className="give-form-group__input">
                <input
                  type="text"
                  className="give-input"
                  placeholder="Doe"
                  value={this.state.lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                />
              </div>
            </div>
          </div>

          <div className="give-form-group">
            <label className="give-label">
              Email address
            </label>
            <div className="give-form-group__input">
              <input
                type="text"
                className="give-input"
                placeholder="hello@example.com"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
          </div>

          <button
            disabled={!this.validPersonalInfo()}
            className="give-button give-button--full-width"
            type="submit"
          >
            Continue
          </button>

          <a className='donate__go-back' onClick={() => this.gotoStep('amount')}>Go back</a>
        </form>
      </div>
    )
  }

  renderMessageStep () {
    return (
      <div className="donate__step donate__step--message" onKeyPress={(e) => e.key === "Enter" && this.gotoStep('subtotal')}>
        <h2 className="give-card__title give-card__title--small">
          Personalize your donation
        </h2>
        <form className="give-form" onSubmit={(e) => this.gotoStep('subtotal', e)}>
          <div className="give-form-group">
            <label className="give-label">
              Leave a message (optional)
            </label>
            <div className="give-form-group__input">
              <textarea
                name="message"
                id="message"
                maxLength={140}
                rows={3}
                className="donate__message give-textarea"
                placeholder="We're all in this together!"
                value={this.state.message}
                onChange={(e) => this.setMessage(e.target.value) }
              >
              </textarea>
            </div>
            <div className="donate__input-helper">
              <span>Remember to be nice.</span>
              <span className="donate__char-count">
                <span className="donate__char-count__value">{ this.state.message.length }</span>
                &nbsp;/ 140 characters
              </span>
            </div>
          </div>

          <div className="give-form-group">
            <label className="give-label">
              Choose a message colour
            </label>
            <div className="give-form-group__input">
              <div className="donate__color">
                <div
                  className={`donate__color__item donate__color__item--1 ${this.state.color == 1 && 'selected'}`}
                  onClick={() => this.setState({ color: 1 })}
                ></div>

                <div
                  className={`donate__color__item donate__color__item--2 ${this.state.color == 2 && 'selected'}`}
                  onClick={() => this.setState({ color: 2 })}
                ></div>

                <div
                  className={`donate__color__item donate__color__item--3 ${this.state.color == 3 && 'selected'}`}
                  onClick={() => this.setState({ color: 3 })}
                ></div>

                <div
                  className={`donate__color__item donate__color__item--4 ${this.state.color == 4 && 'selected'}`}
                  onClick={() => this.setState({ color: 4 })}
                ></div>

                <div
                  className={`donate__color__item donate__color__item--5 ${this.state.color == 5 && 'selected'}`}
                  onClick={() => this.setState({ color: 5 })}
                ></div>

                <div
                  className={`donate__color__item donate__color__item--6 ${this.state.color == 6 && 'selected'}`}
                  onClick={() => this.setState({ color: 6 })}
                ></div>
              </div>
            </div>
          </div>

          <div className="give-form-group">
            <label className="give-label">
              Review privacy settings
            </label>
            <div className="give-form-group__input">
              <div className="give-checkbox-group">
                <input
                  type="checkbox"
                  id="show_name"
                  className="give-checkbox"
                  defaultChecked={this.state.showName}
                  onChange={(e) => this.setState({ showName: e.target.checked })}
                />
                <label htmlFor="show_name" className="give-label give-checkbox-label">
                  Show my name
                </label>
              </div>
            </div>
            <div className="give-form-group__input">
              <div className="give-checkbox-group">
                <input
                  type="checkbox"
                  id="show_amount"
                  className="give-checkbox"
                  defaultChecked={this.state.showAmount}
                  onChange={(e) => this.setState({ showAmount: e.target.checked })}
                />
                <label htmlFor="show_amount" className="give-label give-checkbox-label">
                  Show my donation amount
                </label>
              </div>
            </div>
          </div>

          <button className="donate__button give-button give-button--full-width" type="submit">
            Continue
          </button>

          <a className='donate__go-back' onClick={() => this.gotoStep('personal_info')}>Go back</a>
        </form>
      </div>
    )
  }

  renderSubtotalStep () {
    this.focusSelector("#subtotal-continue-button")

    return (
      <div className="donate__step donate__step--checkout">
        <h2 className="give-card__title give-card__title--small">
          Review your donation
        </h2>
        <form className="give-form" onSubmit={(e) => this.gotoStep('checkout', e)} onKeyPress={(e) => e.key === "Enter" && this.gotoStep('checkout', e)}>
          {
            this.state.changingAmount
              ?
              this.renderAmountSelector()
              :
              <div className="donate__total">
                <span className="donate__total__amount">
                  ${(this.state.totalAmount / 100.0).toFixed(2)}
                </span>
                <a className="donate__total__change" onClick={() => this.setState({ changingAmount: true })}>
                  Change
                </a>
              </div>
          }

          <div className="donate__breakdown">
            <div className="donate__breakdown__group">
              <span className="donate__breakdown__label">
                Your donation
              </span>
              <span className="donate__breakdown__value">
                ${ (this.state.amount / 100.0).toFixed(2) }
              </span>
            </div>

            <div className="donate__breakdown__group">
              <span className="donate__breakdown__label">
                Processing fee
              </span>
              <span className="donate__breakdown__value">
                ${ (this.state.feeAmount / 100.0).toFixed(2) }
              </span>
            </div>

            <div className="donate__breakdown__group">
              <span className="donate__breakdown__label">
                Platform fee
              </span>
              <span className="donate__breakdown__value">
                None
              </span>
            </div>
          </div>
          <p className="donate__text donate__text--light">
            The processing fee covers our payment processing costs. We do not take any other fees on donations to this fund.
          </p>
          <p className="donate__text donate__text--light">
            We'll email you a tax receipt for your donation in early 2022.
          </p>
          <button
            disabled={!!this.state.amountError}
            className="donate__button give-button give-button--full-width"
            type="submit"
            id="subtotal-continue-button"
          >
            Continue
          </button>

          <a className='donate__go-back' onClick={() => this.gotoStep('message')}>Go back</a>
        </form>
      </div>
    )
  }

  renderCheckoutStep () {
    return (
      <Elements stripe={this.stripePromise}>
        <ElementsConsumer>
          {
            ({ elements, stripe }) => (
              <div>
                <h2 className="give-card__title give-card__title--small">
                  Donation method
                </h2>
                <div className="give-form">
                  <div className="give-form-group">
                    <label className="give-label">
                      Credit card
                    </label>
                    <div className="give-form-group__input">
                      <div className="give-stripe-input">
                        <CardElement options={CARD_OPTIONS} onChange={(cardEl) => this.cardChanged(cardEl)} />
                      </div>

                      {
                        this.state.paymentError
                        &&
                        <div className="give-error">{this.state.paymentError}</div>
                      }
                    </div>
                  </div>
                  <button
                    disabled={!this.validEmail() || !this.state.cardComplete || this.state.loading}
                    className={`donate__button give-button give-button--full-width ${this.state.loading && 'give-button--loading'}`}
                    onClick={() => this.submitDonation(elements, stripe)}
                  >
                    { this.state.loading ? '' : 'Complete donation' }
                  </button>

                  <a className='donate__go-back' onClick={() => this.gotoStep('subtotal')}>Go back</a>
                </div>
              </div>
            )
          }
        </ElementsConsumer>
      </Elements>
    )
  }

  renderAmountSelector () {
    if (this.state.showAmountField) {
      return (
        <div className="give-form-group__input">
          <input
            type="text"
            defaultValue={this.state.amount / 100.0}
            className="give-input give-input--dollar"
            onChange={(e) => this.setAmount(100 * parseFloat(e.target.value))}
          />
          {
            this.state.amountError
            &&
            <span className='give-error'>{this.state.amountError}</span>
          }
        </div>
      )
    } else {
      this.focusSelector("#amount")

      return (
        <div className="give-form-group__input">
          <select
            name="amount"
            id="amount"
            defaultValue={this.state.amount}
            className="donate__amount give-select-large"
            onChange={(e) => this.setAmount(parseFloat(e.target.value))}>
            <option value="1000">$10</option>
            <option value="2000">$20</option>
            <option value="5000">$50</option>
            <option value="10000">$100</option>
            <option value="20000">$200</option>
            <option value="other">Other</option>
          </select>
        </div>
      )
    }
  }

  focusSelector (selector: string) {
    setTimeout(() => {
      (document.querySelector(selector) as HTMLInputElement)?.focus()
    }, 100)
  }

  render () {
    return (
      <div className="donate">
        {
          this.state.step === 'amount'
          &&
          this.renderAmountStep()
        }

        {
          this.state.step === 'personal_info'
          &&
          this.renderPersonalInfo()
        }

        {
          this.state.step === 'message'
          &&
          this.renderMessageStep()
        }

        {
          this.state.step === 'subtotal'
          &&
          this.renderSubtotalStep()
        }

        {
          this.state.step === 'checkout'
          &&
          this.renderCheckoutStep()
        }
      </div>
    )
  }
}

const CARD_OPTIONS = {
  iconStyle: 'solid' as 'solid'|'default',
    style: {
      base: {
        iconColor: '#A7AFAF',
        color: '#3E4C4B',
        fontWeight: '500',
        fontFamily: 'Basier Circle, sans-serif',
        fontSize: '14px',
        border: '1px solid #DBE1E0',
        padding: "15",
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {color: '#60807E'},
        '::placeholder': {color: '#A7AFAF'},
      },
      invalid: {
        iconColor: '#EF8181',
        color: '#EF8181',
      },
    },
}

export default OneTimeDonation