import React from 'react'

type Props = {
  title: string
  onConfirm?: () => void
  onCancel?: () => void
}

const Modal = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className="modal">
      <div className="modal__background" onClick={props?.onCancel}></div>
      <div className="modal__frame">
        <div className="modal__title">{props.title}</div>
        <div className="modal__body">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default Modal